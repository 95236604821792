/**
 * .interfaces-panel-interfaceslist-wrapper.
 */

.interfaces-panel-interfaceslist-wrapper {
    
    /**
     * .col-interface.
     */

    .col-interface {

        /**
         * Margin.
         */
    
        margin-bottom: 30px;

        /**
         * .inset.
         */

        .inset {

            /**
             * Position.
             */

            position: relative;

            /**
             * Border.
             */

            border: 3px solid $brand-two;

            /**
             * Overflow.
             */

            overflow: hidden;

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            flex-direction: column;

            /**
             * .col-logo.
             */

            .col-logo {

                /**
                 * Position.
                 */

                position: relative;

                /**
                 * 
                 */

                width: 100%;
                height: 175px;

                /**
                 * 
                 */

                background-color: $brand-three;

                /**
                 * 
                 */

                .image {

                    /**
                     * Position.
                     */

                    position: absolute;
                    top: 50%;
                    left: 50%;

                    /**
                     * Transform.
                     */

                    transform: translateX(-50%) translateY(-50%);

                    /**
                     * Sizing.
                     */

                    max-width: 80%;

                }

            }

            /**
             * .col-info.
             */

            .col-info {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-direction: column;
                justify-content: center;

                /**
                 * Cursor.
                 */
        
                cursor: pointer;

                /**
                 * Sizing.
                 */

                width: 100%;

                /**
                 * Padding.
                 */

                padding-top: 20px;
                padding-bottom: 20px;

                background-color: $brand-one;

                /**
                 * Font related.
                 */

                text-align: center;

                /**
                 * p.
                 */

                p {

                    /**
                     * Margin.
                     */

                    margin-bottom: 0;

                }

            }

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * .bio-container.
                 */

                .bio-container {

                    /**
                     * Transform.
                     */

                    transform: translateY(0);

                }

            }

            /**
             * .bio-container.
             */
            
            .bio-container {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-direction: column;
                justify-content: flex-start;

                /**
                 * Position.
                 */

                position: absolute;
                left: 0;
                top: 0;

                /**
                 * Padding.
                 */

                padding: 25px 30px 20px 25px;

                /**
                 * Transform.
                 */

                transform: translateY(100%);

                /**
                 * Sizing.
                 */

                height: 100%;
                width: 100%;

                /**
                 * Background.
                 */

                background-color: $brand-two;

                /**
                 * Transition.
                 */

                transition: transform 0.5s ease;

                /**
                 * p.
                 */

                p {

                    /**
                     * Font related.
                     */

                    color: $brand-one;

                }

            }

        }

        /**
         * .name.
         */

        .name {

            /**
             * Font related.
             */

            font-size: 20px;
            font-weight: bold;
            color: $brand-three;

        }

        /**
         * .role.
         */

        .role {

            /**
             * Font related.
             */

            font-weight: bold;
            color: $brand-two;

        }

        /**
         * .social-icons-list.
         */

        .social-icons-list {

            /**
             * Position.
             */

            position: absolute;
            bottom: 20px;

        }

    }

}