/**
 * .common-panel-largevideo-wrapper.
 */

.common-panel-largevideo-wrapper {

    /**
     * .video-wrapper.
     */

    .video-wrapper {

        /**
         * Position.
         */

        position: relative;

        /**
         * Font related.
         */

        line-height: 0;

        /**
         * Padding.
         */

        padding-top: 40px;
        padding-bottom: 40px;

        /**
         * .flatvideo-embed-wrapper.
         */

        .flatvideo-embed-wrapper {

            /**
             * Position.
             */

            position: relative;

            /**
             * Margin.
             */

            margin: 0 auto; 

            /**
             * Sizing. 
             */

            max-width: 800px;
            
            /**
             * .flatvideo-embed-container.
             */

            .flatvideo-embed-container {                
                position: relative; 
                padding-bottom: 56.25%; 
                height: 0; 
                overflow: hidden; 
                max-width: 100%;
            } 
          
            /**
             * 
             */

            .flatvideo-embed-container iframe, 
            .flatvideo-embed-container object, 
            .flatvideo-embed-container embed { 
                position: absolute; 
                top: 0; left: 0; 
                width: 100%; 
                height: 100%; 
            }

        }

        /**
         * .posterframe-wrapper.
         */

        .posterframe-wrapper {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            flex-direction: column;
            justify-content: center;
            align-items: center;

            /**
             * Position.
             */

            position: absolute;
            top: 0;
            left: 50%;
            
            /**
             * Sizing.
             */

            width: 90%;
            height: 100%;

            /**
             * Trasform.
             */

            transform: translateX(-50%);

            /**
             * Border.
             */

            border: 2px solid $brand-two;

            /**
             * Padding.
             */

            padding-left: 20px;
            padding-right: 20px;

            /**
             * Background.
             */

            background-color: rgba( $brand-one, 0.9 );

            /**
             * Transition.
             */

            transition: opacity 1s ease;

            /**
             * Z-index.
             */

            z-index: 2;

            /**
             * &.hide.
             */

            &.hide {

                /**
                 * Opacity.
                 */

                opacity: 0;

                /**
                 * Pointer.
                 */

                pointer-events: none;

            }

            /**
             * img, svg.
             */
        
            img, svg {

                /**
                 * Sizing.
                 */

                width: 100px;

                /**
                 * Margin.
                 */

                margin-bottom: 40px;

                /**
                 * circle, path.
                 */

                circle, path {

                    /**
                     * Stroke.
                     */

                    transition: stroke 0.2s ease;

                }

            }

            /**
             * p.title.
             */

            p.title {

                /**
                 * Font related.
                 */

                font-size: 35px;
                line-height: 42px;
                font-weight: bold;
                color: $brand-two;
                text-align: center;

                /**
                 * Margin.
                 */

                margin-bottom: 40px;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {
        
                    /**
                     * Font related.
                     */

                    font-size: 25px;
                    line-height: 32px;
                    
                }

            }

            /**
             * 
             */

            p.cta {

                /**
                 * Font related.
                 */

                font-size: 25px;
                line-height: 30px;
                font-weight: bold;
                text-align: center;

                /**
                 * Margin.
                 */

                margin-bottom: 0;

            }

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * svg.
                 */

                svg {

                    /**
                     * circle, path.
                     */

                    circle, path {

                        /**
                         * Stroke.
                         */

                        stroke: $brand-three !important;

                    }

                }

            }

        }

    }

}
