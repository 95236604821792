/**
 * .casestudies-panel-benefits-wrapper.
 */

.casestudies-panel-benefits-wrapper {

    /**
     * 
     */

    .col-benefit {

        /**
         * Margin.
         */

        margin-bottom: 30px;

        /**
         * .inset.
         */        
         
        .inset {

            /**
             * Display.
             */

            display: flex;

            /**
             * Position.
             */

            position: relative;

            /**
             * Padding.
             */

            padding: 20px 30px 20px 80px;

            /**
             * Sizing.
             */

            height: 100%;
            min-height: 110px;

            /**
             * Border.
             */

            border: 3px solid $brand-two;

            /**
             * &::before.
             */

            &::before {

                /**
                 * Content.
                 */

                content: '';

                /**
                 * Display.
                 */

                display: block;

                /**
                 * Position.
                 */

                position: absolute;
                top: 50%;
                left: -5px;

                /**
                 * Transform.
                 */

                transform: translateY(-50%);

                /**
                 * Sizing.
                 */

                height: 80%;
                width: 10px;

                /**
                 * Background.
                 */

                background-color: black;

            }

            /**
             * .image,
             * object.
             */

            .image,
            object {

                /**
                 * Position.
                 */

                position: absolute;
                top: 50%;
                left: 20px;

                /**
                 * Sizing.
                 */

                width: 80px;

                /**
                 * Transform.
                 */

                transform: translateY(-50%) translateX(-50%);

            }

        }

    }

}