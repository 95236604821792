/**
 * .common-panel-quote-wrapper.
 */

.common-panel-quote-wrapper {

    /**
     * svg.
     */

    svg {

        /**
         * Sizing.
         */

        width: 70px;

        /**
         * Margin.
         */

        margin-right: 30px;
        margin-top: 10px;

        /**
         * Flex.
         */


         flex-grow: 0;
         flex-shrink: 0;
    }

    /**
     * .inset.
     */

    .inset {

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        align-items: flex-start;

        /**
         * Padding.
         */

        padding-left: 100px;
        padding-right: 100px;

        /**
         * p.
         */

        p {

            /**
             * Font related.
             */

            font-size: 25px;
            font-weight: bold;
            line-height: 32px;

            /**
             * Flex.
             */

            flex-grow: 0;
            flex-shrink: 1;

        }

        // /**
        //  * @include.
        //  */

        // @include breakpoint($b-df-mobile-landscape) {

        //     /**
        //      * Padding.
        //      */

        //     padding-left: 0px;
        //     padding-right: 0px;
            
        // }

        /**
         * @include.
         */

        @include breakpoint($b-df-desktop) {
// background-color: palegoldenrod;
            /**
             * Padding.
             */

            padding-left: 0px;
            padding-right: 0px;
            
        }

    }

}