/**
 * .hamburger.
 */

.hamburger {

    /**
     * &:hover.
     */

    &:hover {

        /**
         * svg.
         */

        svg {

            /**
             * rect.
             */

            rect {

                /**
                 * Fill.
                 */

                fill: $brand-two;

            }

        }

    }

    /**
     * svg.
     */

    svg {

        /**
         * Sizing.
         */

        width: 40px;

        /**
         * rect.
         */

        rect {

            /**
             * Fill.
             */

            fill: $brand-three;

        }

    }

}
