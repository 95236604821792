/**
 * .common-panel-ctatwocol-wrapper.
 */

.common-panel-ctatwocol-wrapper {

    /**
     * .col-heading.
     */

    .col-heading {

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        /**
         * 
         */

        .button {

            /**
             * 
             */

            transform: translateY(-10px);

        }

    }

    /**
     * .col-text.
     */

    .col-text {

        /**
         * Padding.
         */

        padding-top: 10px;

    }

}