/**
 * .common-panel-iconblocks-wrapper.
 */

.common-panel-iconblocks-wrapper {

    /**
     * .col-heading.
     */

    .col-heading {

        /**
         * p.heading.
         */

        p.heading {

            /**
             * Font related.
             */

            font-size: 26px;
            line-height: 28px;
            font-weight: bold;
            color: $brand-two;

            /**
             * Margin.
             */

            margin-bottom: 30px;

        }

    }

    /**
     * .col-iconblock.
     */

    .col-iconblock {

        /**
         * Margin.
         */

        margin-bottom: 40px;

        /**
         * .inset.
         */

        .inset {

            /**
             * Padding.
             */

            padding-left: 10px;
            padding-right: 10px;

        }

        /**
         * Font related.
         */

        text-align: center;

        /**
         * img, svg, object.
         */

        img, svg, object {

            /**
             * Sizing.
             */

            width: 85px;

            /**
             * Margin.
             */

            margin-bottom: 5px;

        }

        /**
         * .heading.
         */

        .heading {

            /**
             * Font related.
             */

            color: $brand-two;
            font-size: 20px;
            line-height: 26px;
            font-weight: bold;

        }

        /**
         * p.
         */

        p {

            /**
             * Font related.
             */

            font-size: 18px;;

        }

        /**
         * .button.
         */

        .button {

            /**
             * Margin.
             */

            margin-top: 10px;

        }

        /**
         * .icon-container.
         */

        .icon-container {

            // background-color: palegoldenrod;

        }

        /**
         * 
         */

        .text-container {

            // background-color: paleturquoise;

        }

    }

    /**
     * &.theme-a.
     */

    &.theme-a {

        /**
         * 
         */

    }

    /**
     * &.theme-b.
     */

    &.theme-b {

        /**
         * .col-iconblock.
         */

        .col-iconblock {

            /**
             * > .inset.
             */

            > .inset {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * 
                 */

                text-align: left;

                /**
                 * .icon-container.
                 */

                .icon-container {

                    /**
                     * Margin.
                     */

                    margin-right: 18px;

                    /**
                     * .icon.
                     */

                    .icon {

                    }

                }

                /**
                 * p.heading.
                 */

                p.heading {

                    /**
                     * Font related.
                     */

                    font-size: 20px;
                    color: $brand-three;

                }

                /**
                 * p.
                 */

                p {

                    /**
                     * Font related.
                     */

                    font-size: 16px;

                }

            }

        }

    }

    /**
     * &.theme-c,
     * &.theme-e.
     */

    &.theme-c,
    &.theme-e {

        /**
         * .col-iconblock.
         */

        .col-iconblock {

            /**
             * .icon-container.
             */

            .icon-container {

                /**
                 * Font related.
                 */

                text-align: left;

                /**
                 * Margin.
                 */

                margin-bottom: 8px;

            }

            /**
             * .text-container.
             */
            
            .text-container {
                
                /**
                 * Font related.
                 */
    
                text-align: left;

                /**
                 * ul.
                 */

                ul {

                    /**
                     * Margin.
                     */

                    margin-left: 20px;

                    /**
                     * li.
                     */

                    li {

                        /**
                         * Font related.
                         */

                        font-size: 18px;
                        line-height: 21px;

                        /**
                         * Margin.
                         */

                        margin-bottom: 15px;

                    }

                }

            }

        }

    }

    /**
     * &.theme-d.
     */

    &.theme-d {

        /**
         * .col-iconblock.
         */

        .col-iconblock {

            /**
             * > .inset.
             */

            > .inset {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * p.
                 */

                p {

                    /**
                     * Font related.
                     */

                    font-size: 16px;

                }

                /**
                 * p.heading.
                 */

                p.heading {

                    /**
                     * Font related.
                     */

                    font-size: 20px;

                }

            }

            /**
             * .icon-container.
             */

            .icon-container {

                /**
                 * Font related.
                 */

                text-align: left;

                /**
                 * Margin.
                 */

                margin-bottom: 8px;
                margin-right: 18px;

            }

            /**
             * .text-container.
             */
            
            .text-container {
                
                /**
                 * Font related.
                 */
    
                text-align: left;

                /**
                 * p.heading.
                 */

                p.heading {

                    /**
                     * Font related.
                     */

                    color: $brand-two;

                }

                /**
                 * ul.
                 */

                ul {

                    /**
                     * Margin.
                     */

                    margin-left: 20px;

                    /**
                     * li.
                     */

                    li {

                        /**
                         * Font related.
                         */

                        font-size: 18px;
                        line-height: 21px;

                        /**
                         * Margin.
                         */

                        margin-bottom: 15px;

                    }

                }

            }

        }

    }

    /**
     * &.theme-e.
     */

    &.theme-e {

        /**
         * .col-iconblock.
         */

        .col-iconblock {

            /**
             * > .inset.
             */

            > .inset {

                /**
                 * p.heading.
                 */

                p.heading {

                    /**
                     * Font related.
                     */

                    color: $brand-three;

                }

            }

        }

    }

}
