/**
 * .logo-slider-swiper.
 */

.logo-slider-swiper {

    /**
     * Margin.
     */

    margin-top: 30px;

    /**
     * Border.
     */

    border-left: 1px solid $brand-two;
    border-right: 1px solid $brand-two;

    /**
     * Pointer.
     */

    pointer-events: none;

    /**
     * .swiper-wrapper.
     */

    .swiper-wrapper {

        /**
         * 
         */

        -webkit-transition-timing-function:linear !important; 
        -o-transition-timing-function:linear !important;
        transition-timing-function:linear !important; 

    }

    /**
     * .image.
     */

    .image {

        /**
         * Sizing.
         */

        max-width: 100%;

    }

    /**
     * .swiper-slide.
     */

    .swiper-slide {

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        justify-content: center;
        align-items: center;

        /**
         * Sizing.
         */

        height: 100px !important;

        /**
         * a.
         */

        a {

            /**
             * Pointer.
             */

            pointer-events: all;

            /**
             * Display.
             */

            display: block;
            
        }

    }
      

}
