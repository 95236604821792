/**
 * .common-panel-marketslist-wrapper.
 */

.common-panel-marketslist-wrapper {

    /**
     * .common-panel-marketslist-container.
     */

    .common-panel-marketslist-container {

        /**
         * .col-heading.
         */

        .col-heading {

            /**
             * p.heading.
             */

            p.heading {

                /**
                 * Font related.
                 */

                font-size: 26px;
                line-height: 28px;
                font-weight: bold;
                color: $brand-two;

                /**
                 * Margin.
                 */

                margin-bottom: 30px;

            }

        }

        /**
         * .col-marketitem.
         */

        .col-marketitem {

            /**
             * Margin.
             */

            margin-bottom: 40px;

            /**
             * > .inset.
             */

            > .inset {

                /**
                 * Border.
                 */

                border: 2px solid $brand-two;

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Margin.
                 */

                margin-bottom: 20px;

                /**
                 * &:hover.
                 */

                &:hover {

                    /**
                     * Background.
                     */

                    background-color: $brand-two;

                    /**
                     * p.
                     */

                    p {

                        /**
                         * Font related.
                         */

                        color: $brand-one;

                    }

                }

            }

            /**
             * .heading.
             */

            .heading {

                /**
                 * Font related.
                 */

                color: $brand-two;
                font-size: 20px;
                line-height: 26px;
                font-weight: bold;

                /**
                 * Margin.
                 */

                margin-bottom: 4px;

            }

            /**
             * p.
             */

            p {

                /**
                 * Font related.
                 */

                font-size: 18px;

            }

            /**
             * .button.
             */

            .button {

                /**
                 * Margin.
                 */

                margin-top: 10px;

            }

            /**
             * .image-container.
             */

            .image-container {

                /**
                 * Flex.
                 */

                flex-basis: 140px;

                /**
                 * Sizing.
                 */

                min-width: 140px;


                /**
                 * Background.
                 */

                background-position: center;
                background-size: cover;

                /**
                 * img.
                 */

                img {

                    /**
                     * Display.
                     */

                    display: none;

                }

                /**
                 * &::after.
                 */

                &::after {

                    /**
                     * Content.
                     */

                    content: "";

                    /**
                     * Display.
                     */

                    display: block;

                    /**
                     * Padding.
                     */

                    padding-bottom: 100%;

                }

            }

            /**
             * .text-container.
             */

            .text-container {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-direction: column;
                justify-content: center;

                /**
                 * Flex.
                 */

                flex-grow: 1;

                /**
                 * Paddding.
                 */

                padding-left: 15px;

                /**
                 * p.read-more.
                 */

                p.read-more {
                   
                    /**
                     * Font related.
                     */

                    font-size: 12px;
                    text-transform: uppercase;
                    letter-spacing: 2px;

                }

            }

        }

    }

}