/**
 * .careers-item-wrapper.
 */

.careers-item-wrapper {

    /**
     * .container.
     */

    .container {

        /**
         * Position.
         */

        position: relative;

        /**
         * Padding.
         */

        padding-top: 210px;
        
        /**
         * &::after.
         */

        &::after {

            /**
             * Content.
             */

            content: '';

            /**
             * Position.
             */

            position: absolute;
            bottom: 0;
            left: 15px;
            right: 15px;
            
            /**
             * Sizing.
             */

            height: 1px;

            /**
             * Background.
             */

            background-color: $brand-two;

        }

    }

    /**
     * 
     */

    margin-bottom: 80px;

    /**
     * .col-related.
     */

    .col-related {

        /**
         * Padding.
         */

        padding-bottom: 60px;

        /**
         * 
         */

        margin-top: -5px;

    }

    /**
     * .col-content.
     */

    .col-content {

        /**
         * Padding.
         */

        padding-bottom: 30px;

        /**
         * ul.
         */

        ul {
            
            /**
             * Margin.
             */

            margin-left: 16px;
            margin-bottom: 10px;

            /**
             * li.
             */

            li {

                /**
                 * Font related.
                 */

                color: $brand-two !important;

            }

        }

    }

    /**
     * .col-sidebar.
     */

    .col-sidebar {

        /**
         * .sidebox.
         */

        .sidebox {

            /**
             * .inset.
             */
        
            .inset {

                /**
                 * Padding.
                 */

                padding: 30px 20px 30px 20px;

                /**
                 * Background.
                 */
    
                background-color: $brand-two;

                /**
                 * Font related.
                 */

                color: $brand-one;

                /**
                 * p.heading.
                 */

                p.heading {

                    /**
                     * Font related.
                     */

                    font-size: 20px;
                    font-weight: bold;
                    color: $brand-one;

                    /**
                     * Margin.
                     */

                    margin-bottom: 15px;

                }

                /**
                 * ul.
                 */

                ul {

                    /**
                     * li.
                     */

                    li {

                        /**
                         * List.
                         */

                        list-style: none;

                        /**
                         * Font related.
                         */

                        color: $brand-one;

                        /**
                         * .label.
                         */

                        .label {

                            /**
                             * Font related.
                             */

                            font-weight: bold;

                        }

                    }

                }

            }

        }

    }

    /**
     * .row-hero.
     */

    .row-hero {

        /**
         * Padding.
         */

        padding-bottom: 50px;

        /**
         * p.
         */

        p {

            /**
             * Font related.
             */
            
            font-size: 24px;
            line-height: 31px;

        }

        /**
         * .post-details.
         */

        .post-details {

            /**
             * Margin.
             */

            margin-bottom: 40px;

            /**
             * li.
             */

            li {

                /**
                 * Position.
                 */

                position: relative;

                /**
                 * Display.
                 */

                display: inline-block;

                /**
                 * Font related.
                 */

                font-size: 13px;
                text-transform: uppercase;
                font-weight: bold;

                /**
                 * 
                 */

                padding-left: 7px;
                padding-right: 6px;

                /**
                 * &:first-child.
                 */

                &:first-child {

                    /**
                     * Padding.
                     */

                    padding-left: 0;

                    /**
                     * &::before.
                     */

                    &::before {

                        /**
                         * Content.
                         */

                        content: none;

                    }


                }

                /**
                 * 
                 */

                &::before {

                    /**
                     * Content.
                     */

                    content: '';

                    /**
                     * Position.
                     */

                    position: absolute;
                    left: -2px;
                    top: 50%;

                    /**
                     * Transform.
                     */

                    transform: translateY(-50%);

                    /**
                     * Sizing.
                     */

                    height: 65%;
                    width: 2px;

                    /**
                     * Background.
                     */

                    background-color: rgba($brand-two, 1);

                }

            }

        }

    }

}