/**
 * .common-panel-teammembers-wrapper.
 */

.common-panel-teammembers-wrapper {

    /**
     * Position.
     */

    position: relative;

    overflow: hidden;

    /**
     * .heading.
     */

    .heading {

        /**
         * Font related.
         */

        color: $brand-two;

        /**
         * Margin.
         */

        margin-bottom: 40px;

    }

    /**
     * p.
     */

    p {

        /**
         * Font related.
         */

        color: $brand-three;

    }

    /**
     * .button.
     */

    .button {

        /**
         * Margin.
         */

        margin-top: 30px

    }

    /**
     * .col-text.
     */

    .col-text {

        /**
         * Position.
         */

        position: relative;

        /**
         * Z-index.
         */

        z-index: 6;
        
        /**
         * strong.
         */

        strong {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * Margin.
             */
        
            margin-top: 20px;

            /**
             * Font related.
             */

            font-size: 20px;
            font-weight: 300;
            line-height: 26px;
            color: $brand-two;
            text-transform: uppercase;

        }

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Margin.
             */

            margin-bottom: 30px;

            /**
             * Font related.
             */

            text-align: center;

        }

    }

    /**
     * .arrow-previous,
     * .arrow-next.
     */

    .arrow-previous,
    .arrow-next {

        /**
         * path, polyline.
         */

        path, polyline {

            /**
             * Fill.
             */

            fill: $brand-three !important;

        }

        /**
         * 
         */

        &:hover {

            /**
             * path, polyline.
             */

            path, polyline {

                /**
                 * Fill.
                 */

                fill: $brand-two !important;

            }

        }

    }

    /**
     * .swiper-slide.
     */

    .swiper-slide {

        /**
         * .inset.
         */

        .inset {

            /**
             * Display.
             */

            display: block;

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * .name, .role.
                 */

                .name, .role {

                    /**
                     * Font related.
                     */

                    color: $brand-two;

                }

            }

            /**
             * .image-container.
             */

            .image-container {

                /**
                 * Background.
                 */

                background-color: transparent;

                /**
                 * Background.
                 */

                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;

                /**
                 * img.
                 */

                img {

                    /**
                     * Display.
                     */

                    display: none;

                }

                /**
                 * Margin.
                 */

                margin-bottom: 12px;

                /**
                 * Border.
                 */

                border: 2px solid $brand-two;

                /**
                 * Overflow.
                 */

                overflow: hidden;

                /**
                 * Shadow.
                 */

                box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px;

                /**
                 * &::after.
                 */
        
                &::after {
        
                    /**
                     * Content.
                     */
        
                    content: "";
        
                    /**
                     * Display.
                     */
        
                    display: block;
        
                    /**
                     * Padding.
                     */
        
                    padding-bottom: 100%;
        
                }

            }

            /**
             * .details-container.
             */

            .details-container {

                /**
                 * Font related.
                 */

                // text-align: center;
                text-transform: uppercase;

                /**
                 * .name.
                 */

                .name {

                    /**
                     * Margin.
                     */

                    margin-bottom: 5px;
                    
                    /**
                     * Font related.
                     */

                    font-size: 20px;
                    font-weight: bold;

                }

                /**
                 * .role.
                 */

                .role {

                    /**
                     * Font related.
                     */

                    font-size: 14px;
                    font-weight: bold;
                    color: $brand-two;

                }

            }

        }

    }

    /**
     * .col-teammembers.
     */

    .col-teammembers {

        /**
         * Position.
         */

        position: relative;

        /**
         * Z-index.
         */

        z-index: 6;

        /**
         * .image.
         */

        .image {

            /**
             * Sizing.
             */

            width: 100%;

        }

        /**
         * .slider-wrapper.
         */

        .slider-wrapper {

            /**
             * Padding.
             */

            padding-left: 50px;
            padding-right: 50px;

            /**
             * Position.
             */

            position: relative;

            /**
             * .arrow-previous,
             * .arrow-next.
             */

            .arrow-previous,
            .arrow-next {

                /**
                 * Position.
                 */

                position: absolute;
                top: 50%;

                /**
                 * Z-index.
                 */

                z-index: 10;

                /**
                 * Sizing.
                 */

                width: 15px;
                height: 15px;

                /**
                 * Transform.
                 */

                transform: translateY(-50%);

            }

            /**
             * .arrow-previous.
             */

            .arrow-previous {

                /**
                 * Position.
                 */

                left: 0px;

            }

            /**
             * .arrow-next.
             */

            .arrow-next {

                /**
                 * Position.
                 */

                right: 0px;

            }

        }

    }

    /**
     * .bio-container.
     */

    .bio-container {

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        flex-direction: column;
        justify-content: center;

        /**
         * Padding.
         */

        padding-left: 60px;
        padding-right: 160px;

        /**
         * Position.
         */

        position: absolute;
        left: 0%;
        right: 0;
        top: 0;

        /**
         * @include.
         */

        @include breakpoint($b-df-tablet) {
            
            /**
             * Position.
             */

            left: 0

        }

        /**
         * Transform.
         */

        transform: translateY(100%);

        /**
         * Sizing.
         */

        height: 100%;

        /**
         * Z-index.
         */

        z-index: 10;
       
        /**
         * Background.
         */

        background-color: $brand-two;

        /**
         * Transition.
         */

        transition: transform 0.4s ease;

        /**
         * Overflow.
         */

        overflow: hidden;

        /**
         * &.show.
         */

        &.show {

            /**
             * Position related.
             */

            top: 0;

            transform: translateY(0);

        }
        
        /**
         * .bio-text-wrapper.
         */

        .bio-text-wrapper {

            /**
             * p.
             */

            p {
            
                /**
                 * Font related.
                 */

                color: $brand-one;
                font-size: 18px;
                line-height: 24px;
            
            }

        }

        /**
         * p.
         */

        p {

            /**
             * Font related.
             */

            color: $brand-three;

        }

        /**
         * .name.
         */

        .name {

            /**
             * Margin.
             */

            margin-bottom: 2px;

            /**
             * Font related.
             */

            font-size: 24px;
            line-height: 28px;
            font-weight: bold;
            color: $brand-one;

        }

        /**
         * .role.
         */

        .role {

            /**
             * Font related.
             */

            font-size: 16px;
            line-height: 22px;
            font-weight: bold;
            color: $brand-one;

        }

        /**
         * .close-popup.
         */

        .close-popup {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * Margin.
             */

            margin-bottom: 40px;

            /**
             * svg.
             */

            svg {

                /**
                 * Sizing.
                 */

                width: 25px;

                /**
                 * polygon.
                 */

                polygon {

                    /**
                     * Fill.
                     */

                    fill: $brand-one;

                }

            }

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * polygon.
                 */

                polygon {

                    /**
                     * 
                     */

                    fill: $brand-three !important;

                }

            }

        }

        /**
         * .linkedin.
         */

        .linkedin {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * Margin.
             */

            margin-top: 20px;

            /**
             * svg.
             */

            svg {

                /**
                 * Sizing.
                 */
    
                width: 20px;

                /**
                 * path.
                 */

                path {

                    /**
                     * Fill.
                     */

                    fill: $brand-one !important;

                }

            }

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * path.
                 */

                path {

                    /**
                     * Fill.
                     */

                    fill: $brand-three !important;

                }

            }

        }        

    }

}
