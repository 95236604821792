/**
 * .common-panel-companylogoslider-wrapper.
 */

.common-panel-companylogoslider-wrapper {

    /**
     * .row-heading.
     */

    .row-heading {

        /**
         * Font related.
         */

        text-align: center;

    }

    /**
     * .row-text.
     */

    .row-text {

        /**
         * Font related.
         */

        text-align: center;

    }

}