/**
 * .news-panel-listing-wrapper,
 * .clients-panel-listing-wrapper.
 */

.news-panel-listing-wrapper,
.clients-panel-listing-wrapper {

    /**
     * .filters.
     */

    .filters {

        /**
         * li.
         */

        li {

            /**
             * Font related.
             */

            font-weight: bold;
            text-transform: uppercase;

            /**
             * Display.
             */

            display: inline-block;

            /**
             * List.
             */

            list-style: none;

            /**
             * Margin.
             */

            margin-bottom: 40px;

            /**
             * Margin.
             */

            margin-right: 15px;

            /**
             * a.
             */

            a {

                /**
                 * Font related.
                 */

                color: $brand-three;

                /**
                 * &:hover.
                 */

                &:hover {

                    color: $brand-two;

                }

            }

        }

    }

    /**
     * .filters li.active.
     */

    .filters li.active {

        /**
         * a.
         */

        a {

            /**
             * Font related.
             */

            color: $brand-two;

        }

    }

    /**
     * .news-list,
     * .clients-list.
     */

    .news-list,
    .clients-list {

        /**
         * .grid-sizer.
         */

        .grid-sizer {

            /**
             * Used in JS.
             */

        }

        /**
         * .list-item.
         */

        .list-item {

            /**
             * Margin.
             */

            margin-bottom: 30px;

            /**
             * Display.
             */

            display: none;

            /**
             * &.show.
             */

            &.show {

                /**
                 * Display.
                 */

                display: block;

            }

        }

    }

}
