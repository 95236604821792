/**
 * .common-panel-latestnewsteaser-wrapper.
 */

.common-panel-latestnewsteaser-wrapper {

    /**
     * .common-panel-latestnewsteaser-container.
     */

    .common-panel-latestnewsteaser-container {

        /**
         * .col.
         */

        .col {

            /**
             * Display.
             */

            display: flex;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {
                
                /**
                 * Display.
                 */

                display: block;
                
            }

            /**
             * .prefix-wrapper.
             */

            .prefix-wrapper {

                flex-grow: 1;

                /**
                 * Padding.
                 */

                padding-right: 15px;

                /**
                 * p.
                 */

                p {

                    /**
                     * Font related.
                     */

                    white-space: nowrap;
                    font-size: 20px;
                    font-weight: bold;
                    color: $brand-two;

                }

            }

            /**
             * .text-wrapper.
             */

            .text-wrapper {

                /**
                 * Flex.
                 */

                flex-grow: 1;

                /**
                 * p.
                 */

                p {

                    /**
                     * 
                     */

                    display: inline-block;

                    /**
                     * Font related.
                     */

                    font-size: 19px;
                    line-height: 24px;

                    /**
                     * a.
                     */

                    a {

                        /**
                         * Padding.
                         */

                        padding-left: 10px;

                        /**
                         * Font related.
                         */

                        font-size: 16px;
                        font-weight: bold;
                        text-transform: uppercase;

                        /**
                         * @include.
                         */

                        @include breakpoint($b-df-mobile-landscape) {
                            
                            /**
                             * Display.
                             */

                            display: block;

                            /**
                             * Padding.
                             */

                            padding-top: 6px;
                            padding-left: 0;
                            
                        }

                    }

                }

            }

        }

    }

}
