/**
 * .medium-zoom-overlay.
 */

.medium-zoom-overlay {

    /**
     * Z-index.
     */

    z-index: 100;

    /**
     * Background.
     */

    background-color: $brand-one !important;

}

/**
 * .medium-zoom-image--opened.
 */

.medium-zoom-image--opened {

    /**
     * Z-index.
     */

    z-index: 101;

}
