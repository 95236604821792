/**
 * .lottie-wrapper.
 */

.lottie-wrapper {

    /**
     * Z-index.
     */

    z-index: 50;

    /**
     * lottie-player.
     */

    lottie-player {

        /**
         * Display.
         */

        display: inline-block;

        /**
         * &.hidden.
         */

        &.hidden {

            /**
             * Opacity.
             */

            opacity: 0;

        }

        /**
         * Transition.
         */

        transition: opacity 0.3s ease;

    }

    /**
     * &--center.
     */

    &--center {

        /**
         * Font related.
         */

        text-align: center;

    }

    /**
     * &--width-90pc lottie-player.
     */

    &--width-90pc lottie-player {

        /**
         * Sizing.
         */

        width: 90%;

    }

    /**
     * &--width-80pc lottie-player.
     */

    &--width-80pc lottie-player {

        /**
         * Sizing.
         */

        width: 80%;

    }

    /**
     * &--width-75pc.
     */

    &--width-75pc lottie-player {

        /**
         * Sizing.
         */

        width: 75%;

    }

    /**
     * &--width-70pc.
     */

    &--width-70pc lottie-player {

        /**
         * Sizing.
         */

        width: 70%;

    }

    /**
     * &--width-65pc.
     */

    &--width-65pc lottie-player {

        /**
         * Sizing.
         */

        width: 65%;

    }

    /**
     * &--small.
     */

    &--small {

        /**
         * lottie-player.
         */

        lottie-player {

            /**
             * Sizing.
             */

            height: 150px !important;

        }

    }

    /**
     * &--homepage.
     */

    &--homepage {

        /**
         * Margin.
         */

        margin-bottom: 0;

        /**
         * Position.
         */

        position: absolute;
        top: 50%;
        left: 55%;

        /**
         * Transform.
         */

        transform: translateY(-50%);

        /**
         * Z-index.
         */

        z-index: 0;

        /**
         * @include.
         */

        @include breakpoint($b-df-tablet) {

            /**
             * Display.
             */

            display: none;

        }

        /**
         * lottie-player.
         */

        lottie-player {

            /**
             * Sizing.
             */
            
            width: 55vw;

        }

    }

    /**
     * &--hero.
     */

    &--hero {

        /**
         * Border.
         */

        border: none;

        /**
         * Position.
         */

        position: absolute;
        left: 0;
        top: 0;

        /**
         * Sizing.
         */

        width: 150vw;
        
        /**
         * Transform.
         */

        transform: translateY(-40%) translateX(10%);

    }
    
    /**
     * &--design.
     */

    &--design {

        /**
         * Sizing.
         */

        width: 65vw;
        
        /**
         * Transform.
         */

        transform: translateY(-8%) translateX(80%);

    }

    /**
     * &--deploy.
     */

    &--deploy {

        /**
         * Sizing.
         */

        width: 120vw;
        
        /**
         * Transform.
         */

        transform: translateY(-46%) translateX(30%);

    }

    /**
     * &--ingest.
     */

    &--ingest {

        /**
         * Sizing.
         */

        width: 120vw;
        
        /**
         * Transform.
         */

        transform: translateY(-10%) translateX(32%);

    }

    /**
     * &--engine.
     */

    &--engine {

        /**
         * Sizing.
         */

        width: 110vw;
        
        /**
         * Transform.
         */

        transform: translateY(-18%) translateX(17%);

    }

    /**
     * &--monitor.
     */

    &--monitor {

        /**
         * Sizing.
         */

        width: 100vw;
        
        /**
         * Transform.
         */

        transform: translateY(-10%) translateX(16%);

    }

    /**
     * &--platform.
     */

    &--platform {

        /**
         * Sizing.
         */

        width: 100vw;
        
        /**
         * Transform.
         */

        transform: translateY(0%) translateX(10%);

    }

    /**
     * &--center.
     */

    &--center {

        /**
         * Background.
         */

        text-align: center;

    }

}

/**
 * .platform-hero.
 */

.platform-hero {

    /**
     * Padding.
     */

    padding-bottom: 22vw;

    /**
     * @include.
     */

    @include breakpoint($b-tablet-only) {

        /**
         * Padding.
         */

        padding-bottom: 10vw !important;

    }

}

/**
 * .z-index-above-graphic.
 */

.z-index-above-graphic {

    /**
     * Z-index.
     */

    z-index: 51;

}
