/**
 * .common-panel-textandimagecolumns-wrapper.
 */

.common-panel-textandimagecolumns-wrapper {

    /**
     * .common-panel-textandimagecolumns-container.
     */

    .common-panel-textandimagecolumns-container {

        /**
         * 
         */

        // background-color: paleturquoise;

        /**
         * .row-content.
         */

        .row-content {

            /**
             * &.reverse-columns.
             */

            &.reverse-columns {

                /**
                 * Flex.
                 */

                flex-direction: row-reverse;

            }

        }

        /**
         * .col-text.
         */

        .col-text {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            flex-direction: column;
            justify-content: center;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {
                
                /**
                 * Margin.
                 */

                margin-bottom: 25px;
                
            }

            /**
             * .heading.
             */

            .heading {

                /**
                 * Font related.
                 */

                font-size: 26px;
                line-height: 30px;
                color: $brand-two;

            }

            /**
             * ul.
             */

            ul {

                /**
                 * Margin.
                 */

                margin-left: 16px;

                /**
                 * li.
                 */

                li {

                    /**
                     * Margin.
                     */

                    margin-bottom: 4px;

                }

            }

        }

        /**
         * .col-image.
         */

        .col-image {

            /**
             * img.
             */

            img {

                /**
                 * Sizing.
                 */

                max-width: 100%;
                max-height: 380px;

            }

        }

    }

}