/**
 * .common-panel-teammembersgrid-wrapper.
 */

.common-panel-teammembersgrid-wrapper {

    /**
     * .common-panel-teammembersgrid-container.
     */

    .common-panel-teammembersgrid-container {

        /**
         * 
         */

        // background-color: palegreen;

        /**
         * h2.heading.
         */

        h2.heading {

            /**
             * Font related.
             */

            color: $brand-two;

        }

        /**
         * .row-teammembers.
         */

        .row-teammembers {

            /**
             * Margin.
             */

            margin-top: 30px;

        }

        /**
         * .col-teammember.
         */

        .col-teammember {

            /**
             * Margin.
             */

            margin-bottom: 20px;

            /**
             * .inset.
             */

            .inset {

                /**
                 * .name-container.
                 */

                .name-container {

                    /**
                     * 
                     */

                    // background-color: palegoldenrod;

                    /**
                     * Padding.
                     */

                    padding-top: 15px;

                    /**
                     * p.
                     */

                    p.name {

                        /**
                         * Font related.
                         */

                        font-size: 23px;
                        font-weight: bold;
                        line-height: 30px;

                        /**
                         * Margin.
                         */
                    
                        margin-bottom: 6px

                    }

                }

                /**
                 * .role-container.
                 */

                .role-container {

                    /**
                     * Display.
                     */

                    display: flex;

                    /**
                     * p.role.
                     */

                    p.role {

                        /**
                         * Font related.
                         */

                        font-size: 15px;
                        line-height: 24px;
                        
                        /**
                         * Margin.
                         */

                        margin-right: 10px;
                        margin-bottom: 0;

                    }

                    /**
                     * a.linkedin.
                     */

                    a.linkedin {

                        /**
                         * Padding.
                         */

                        padding-top: 3px;
                        
                        /**
                         * Display.
                         */

                        display: inline-block;

                        /**
                         * svg.
                         */
    
                        svg {
    
                            /**
                             * Sizing.
                             */
    
                            width: 16px;
    
                        }

                        /**
                         * &:hover.
                         */

                        &:hover {

                            /**
                             * svg.
                             */
                        
                            svg {

                                /**
                                 * path.
                                 */

                                path {

                                    /**
                                     * Font related.
                                     */

                                    fill: white !important;

                                }

                            }

                        }

                    }

                    /**
                     * p.role.
                     */

                    p.role {

                        /**
                         * Font related.
                         */

                        font-weight: bold;
                        color: $brand-two;

                    }

                }

                /**
                 * .image-container.
                 */

                .image-container {

                    /**
                     * Sizing.
                     */

                    width: 100%;

                    /**
                     * Border.
                     */

                    border: 2px solid $brand-two;

                    /**
                     * img.
                     */

                    img {

                        /**
                         * Display.
                         */

                        display: none;

                    }

                    /**
                     * Background.
                     */

                    background-position: center;
                    background-size: cover;

                    /**
                     * &::after.
                     */

                    &::after {

                        /**
                         * Content.
                         */

                        content: "";

                        /**
                         * Display.
                         */

                        display: block;

                        /**
                         * Padding.
                         */

                        padding-bottom: 100%;

                    }

                }


            }

        }

    }

}