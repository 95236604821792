/**
 * .casestudies-panel-capabilities-wrapper.
 */

 .casestudies-panel-capabilities-wrapper {

    /**
     * 
     */

    ul {

        /**
         * Margin.
         */

        margin-left: 20px;

        /**
         * li.
         */

        li {

            color: $brand-two;

        }

    }

}

