/**
 * .common-panel-technologyslides-wrapper.
 */

.common-panel-technologyslides-wrapper {
   
    /**
     * .row-heading.
     */

    .row-heading {

        /**
         * 
         */

        margin-bottom: 40px;

        /**
         * 
         */

        h2 {

            margin: 0;

        }

    }

    /**
     * .col-pagination.
     */

    .col-pagination {

        /**
         * Font related.
         */

        text-align: right;

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        justify-content: flex-end;
        align-items: flex-end;

    }

    /**
     * .slide.
     */

    .slide {

        /**
         * Border.
         */

        // border: 5px solid olive;
        
        /**
         * Display.
         */

        display: inline-flex;
        
        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Flex.
             */

            flex-direction: column-reverse;
            
        }

        /**
         * .col-left.
         */
    
        .col-left {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            flex-direction: column;
            flex-basis: 50%;
            justify-content: center;
            align-items: flex-start;

            /**
             * Background.
             */
    
            // background-color: cornflowerblue;
         
            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                // background-color: brown;

            }

            /**
             * Border.
             */

            margin-right: 60px;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Margin.
                 */

                margin-right: 0;
                
                /**
                 * Padding.
                 */

                padding-top: 20px;

            }

            /**
             * .image.
             */
        
            .image {

                /**
                 * Sizing.
                 */

                width: 80px;

                /**
                 * Margin.
                 */

                margin-right: 20px;

            }

            /**
             * .icon-text.
             */

            .icon-text {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                align-items: flex-start;

                /**
                 * Margin.
                 */

                margin-bottom: 25px;

            }

            /**
             * .title.
             */

            .title {

                /**
                 * Font related.
                 */

                font-weight: bold;
                color: $brand-two;
                font-size: 16px;

                /**
                 * Margin.
                 */

                margin-bottom: 20px;

            }

        }
    
        /**
         * .col-right.
         */
    
        .col-right {
    
            /**
             * Flex.
             */

            flex-basis: 50%;

            /**
             * Background.
             */
    
            // background-color: darkgoldenrod;
    
            /**
             * img.
             */
    
            img {
    
                /**
                 * Sizing.
                 */
    
                max-width: 100%;
    
            }
    
        }

        /**
         * .page-container.
         */

        .page-container {

            /**
             * Margin.
             */

            margin-top: 35px;

            /**
             * Display.
             */

            display: inline-flex;

            /**
             * Flex.
             */

            align-items: center;

        }

        /**
         * .pagination-items-list.
         */

        .pagination-items-list {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * Margin.
             */

            margin-right: 9px;

            /**
             * li.
             */

            li {

                /**
                 * Display.
                 */

                display: inline-block;

                /**
                 * Sizing.
                 */

                width: 12px;
                height: 12px;

                /**
                 * Background.
                 */

                background-color: $brand-three;

                /**
                 * Border.
                 */

                border-radius: 12px;

                /**
                 * Cursor.
                 */

                cursor: pointer;

                /**
                 * span.
                 */

                span {

                    /**
                     * Display.
                     */

                    display: none;

                }

                /**
                 * &.active.
                 */

                &.active {

                    /**
                     * Background.
                     */

                    background-color: $brand-two;

                }

                /**
                 * &:hover.
                 */

                &:hover {

                    /**
                     * Background.
                     */

                    background-color: $brand-two;

                }

            }

        }

        /**
         * .page.
         */

        .page {

            /**
             * Background.
             */            

            background-color: $brand-two;

            /**
             * Display.
             */

            display: inline-block;

            /**
             * Padding.
             */

            padding: 2px 4px 2px 4px;

            /**
             * Margin.
             */

            margin-bottom: 0;

            /**
             * Font related.
             */

            font-size: 12px;
            color: $brand-one;

        }

    }

    /**
     * .pagination-list.
     */

    .pagination-list {

        /**
         * li.
         */

        li {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * Margin.
             */

            margin-right: 10px;

            /**
             * Font related.
             */

            font-weight: bold;
            text-transform: uppercase;
            font-size: 13px;

            /**
             * &:last-child.
             */

            &:last-child {

                /**
                 * Margin.
                 */

                margin-right: 0;

            }

            /**
             * a.
             */

            a {

                /**
                 * Font related.
                 */

                color: $brand-three;

                /**
                 * &:hover,
                 * &.active.
                 */
                
                &:hover,
                &.active {
    
                    /**
                     * Font related.
                     */
                    
                    color: $brand-two;
    
                }

            }

        }

    }

}