/**
 * @mixin.
 */

@mixin inputTextStyle {

    /**
     * Font related.
     */

    font-size: 14px;
    line-height: 21px;

    /**
     * &::placeholder.
     */

    &::placeholder {

        /**
         * Font related.
         */

        font-size: 14px;
        line-height: 21px;

    }

}

/**
 * .submitted-message.
 */

.submitted-message {

    /**
     * Sizing.
     */

    min-height: 200px;

}

/**
 * .contact-panel-details-wrapper.
 */

.contact-panel-details-wrapper {

    /**
     * .wpforms-field-label.
     */

    .wpforms-field-label {

        /**
         * Font related.
         */

        font-weight: normal !important;
        color: white;
        font-size: 15px !important;
        
        /**
         * Padding.
         */

        padding-bottom: 5px !important;

    }

    /**
     * .wpforms-confirmation-container-full.
     */

    .wpforms-confirmation-container-full {

        /**
         * Background.
         */

        background-color: $brand-two !important;

        /**
         * Border.
         */

        border: 0 !important;

        /**
         * p.
         */

        p {

            /**
             * Font related.
             */

            color: $brand-one !important;

        }

    }

    /**
     * .wpforms-form.
     */

    .wpforms-form {

        /**
         * Padding.
         */

        padding-right: 20px;

        /**
         * label.wpforms-error.
         */

        label.wpforms-error {

            /**
             * Padding.
             */

            padding-top: 5px;

            /**
             * Font related.
             */

            color: $brand-two !important;

        }

        /**
         * .wpforms-field-text,
         * .wpforms-field-email.
         */

        .wpforms-field-text,
        .wpforms-field-email {
            
            /**
             * Padding.
             */

            padding-top: 0 !important;

            /**
             * input.
             */

            input {

                /**
                 * Sizing.
                 */

                max-width: 100% !important;
                
                /**
                 * @include.
                 */

                @include inputTextStyle();

            }

        }

        /**
         * wpforms-field-textarea.
         */

        .wpforms-field-textarea {

            /**
             * Margin.
             */

            margin-bottom: 15px;

            /**
             * textarea.
             */

            textarea {

                /**
                 * @include.
                 */

                @include inputTextStyle();

            }

        }

        /**
         * .wpforms-field-html.
         */

        .wpforms-field-html {

            /**
             * p.
             */

            p {

                /**
                 * Font related.
                 */

                line-height: 1.4;

            }

        }

        /**
         * .wpforms-field-checkbox.
         */

        .wpforms-field-checkbox {

            /**
             * Padding.
             */

            padding-bottom: 0 !important;

            /**
             * .wpforms-field-label-inline.
             */

            .wpforms-field-label-inline {

                /**
                 * Font related.
                 */

                font-size: 16px !important;

            }

            /**
             * ul.
             */

            ul {
                
                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-wrap: wrap;

                /**
                 * li.
                 */

                li {
                
                    /**
                     * Sizing.
                     */

                    // width: 33%;
                
                    /**
                     * Padding.
                     */

                    padding-bottom: 5px !important;

                    /**
                     * Cursor.
                     */

                    cursor: pointer;

                    /**
                     * label.
                     */

                    label {
                        
                        /**
                         * Cursor.
                         */

                        cursor: pointer;

                        /**
                         * Font related.
                         */

                        font-size: 14px;
                        line-height: 21px;

                    }

                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * label.
                         */

                        label {

                            /**
                             * Opacity.
                             */

                            opacity: 0.9;

                        }

                    }

                }

            }

        }

        /**
         * .wpforms-submit-container.
         */

        .wpforms-submit-container {

            /**
             * Padding.
             */

            padding-top: 20px !important;

            /**
             * button.
             */

            button {

                /**
                 * Border.
                 */

                border: 1px solid $brand-two !important;

                /**
                 * Background.
                 */

                background-color: transparent !important;

                /**
                 * Padding.
                 */

                padding-left: 30px !important;
                padding-right: 30px !important;
                padding-top: 12px !important;
                padding-bottom: 12px !important;

                /**
                 * Font related.
                 */

                font-size: 12px !important;
                color: $brand-three !important;
                text-transform: uppercase;
                letter-spacing: 2px;

            }

        }

    }



}
