/**
 * .ourpeople-panel-teammembers-wrapper.
 */

.ourpeople-panel-teammembers-wrapper {
    
    /**
     * .col-teammember.
     */

    .col-teammember {

        /**
         * Margin.
         */
    
        margin-bottom: 30px;

        /**
         * .inset.
         */

        .inset {

            /**
             * Position.
             */

            position: relative;

            /**
             * Border.
             */

            border: 3px solid $brand-two;

            /**
             * Overflow.
             */

            overflow: hidden;

            /**
             * Display.
             */

            display: flex;

            /**
             * Sizing.
             */

            height: 100%;

            /**
             * .col-photo.
             */

            .col-photo {

                /**
                 * Sizing.
                 */

                width: 40%;
                height: 280px;

                /**
                 * 
                 */

                background-position: center;
                background-size: cover;

                /**
                 * .image.
                 */

                .image {

                    /**
                     * Display.
                     */

                    display: none;

                    /**
                     * Sizing.
                     */

                    max-width: 100%;

                }

                /**
                 * @include.
                 */

                @include breakpoint($b-df-tablet) {

                    /**
                     * Background.
                     */

                    background-position: center top;

                }

            }

            /**
             * .col-info.
             */

            .col-info {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-direction: column;
                justify-content: center;

                /**
                 * Cursor.
                 */
        
                cursor: pointer;

                /**
                 * Sizing.
                 */

                width: 60%;

                /**
                 * Padding.
                 */

                padding-left: 30px;

            }

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * .bio-container.
                 */

                .bio-container {

                    /**
                     * Transform.
                     */

                    transform: translateY(0);

                }

            }

            /**
             * .bio-container.
             */
            
            .bio-container {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-direction: column;
                justify-content: flex-start;

                /**
                 * Position.
                 */

                position: absolute;
                left: 0;
                top: 0;

                /**
                 * Padding.
                 */

                padding: 30px 30px 20px 25px;

                /**
                 * Transform.
                 */

                transform: translateY(100%);

                /**
                 * Sizing.
                 */

                height: 100%;
                width: 100%;

                /**
                 * Background.
                 */

                background-color: $brand-two;

                /**
                 * Transition.
                 */

                transition: transform 0.5s ease;

                /**
                 * p.
                 */

                p {

                    /**
                     * Font related.
                     */

                    color: $brand-one;

                }

                /**
                 * .name-and-social.
                 */

                .name-and-social {

                    /**
                     * Display.
                     */

                    display: flex;

                    /**
                     * Flex.
                     */

                    justify-content: space-between;

                }

                /**
                 * .social-icons-list.
                 */

                .social-icons-list {

                    /**
                     * Position.
                     */

                    position: relative;
                    bottom: auto;

                    /**
                     * svg.
                     */

                    svg {

                        /**
                         * path.
                         */

                        path {

                            /**
                             * Fill.
                             */

                            fill: $brand-one

                        }

                    }

                }

            }

        }

        /**
         * .name.
         */

        .name {

            /**
             * Font related.
             */

            font-size: 24px;
            font-weight: bold;
            color: $brand-three;

        }

        /**
         * .role.
         */

        .role {

            /**
             * Font related.
             */

            font-weight: bold;
            color: $brand-two;

        }

        /**
         * .social-icons-list.
         */

        .social-icons-list {

            /**
             * Position.
             */

            position: absolute;
            bottom: 20px;

        }

    }

}