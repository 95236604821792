/**
 * .home-panel-hero-wrapper.
 */

.home-panel-hero-wrapper {

    /**
     * Background.
     */

    // background-image: url(/assets/images/home/header-background.jpg);
    background-size: cover;

    /**
     * Padding.
     */

    padding-top: 270px;
    padding-bottom: 50px;

    /**
     * Position.
     */

    position: relative;

    /**
     * @include.
     */

    @include breakpoint($b-df-tablet) {

        /**
         * Padding.
         */

        padding-top: 180px;
        
    }

    /**
     * Font related.
     */

    text-align: center;

    /**
     * Border.
     */

    border-bottom: 1px solid rgba($brand-two, 1);

    /**
     * h1.
     */

    h1 {

        /**
         * Font related.
         */

        color: $brand-two;

        /**
         * Margin.
         */

        margin-bottom: 40px;

        /**
         * Position.
         */

        position: relative;

        /**
         * Z-index.
         */

        z-index: 1;

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Font related.
             */

            font-size: 32px;
            
            /**
             * Margin.
             */

            margin-bottom: 30px;

        }

    }

    /**
     * p.
     */

    p {

        /**
         * Font related.
         */

        font-size: 24px;
        line-height: 27px;

        /**
         * Position.
         */

        position: relative;

        /**
         * Z-index.
         */

        z-index: 1;

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Font related.
             */

            line-height: 32px;
            
        }

    }

    /**
     * .company-logos-list.
     */

    .company-logos-list {

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */
    
        justify-content: space-between;
        align-items: center;

        /**
         * Margin.
         */

        margin-top: 60px;

        /**
         * li.
         */

        li {

            /**
             * List.
             */

            list-style: none;

        }

    }

    /**
     * .header-background.
     */

    .header-background {

        /**
         * Position.
         */

        position: absolute;
        top: 0;
        left: 0;

        /**
         * Sizing.
         */

        width: 100%;
        height: 100%;

        /**
         * Overflow.
         */

        overflow: hidden;

        /**
         * Opacity.
         */

        opacity: 0.35;

        /**
         * Pointer.
         */

        pointer-events: none;

        /**
         * Z-index.
         */

        z-index: 0;

        /**
         * Object.
         */
        
        object {
            
            /**
             * Position.
             */

            position: absolute;
            top: 50%;
            left: 50%;
            
            /**
             * &.height-100.
             */

            &.height-100 {

                /**
                 * Sizing.
                 */

                height: 100%;

            }

            /**
             * &.width-100.
             */

            &.width-100 {

                /**
                 * Sizing.
                 */

                width: 100%;
                
            }
            
            /**
             * Transform.
             */

            transform: translateX(-50%) translateY(-50%);

        }

    }

    /**
     * .animated-text-container.
     */

    .animated-text-container {

        /**
         * p.
         */

        p {

            /**
             * Font related.
             */

            color: $brand-two;
            font-size: 28px;

            /**
             * span.
             */

            span {

                /**
                 * Font related.
                 */

                color: $brand-three;

            }

        }

    }

}
