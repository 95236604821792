/**
 * .casestudies-panel-relatednews-wrapper.
 */

.casestudies-panel-relatednews-wrapper {

    /**
     * .list-item.
     */

    .list-item {

        /**
         * Margin.
         */

        margin-bottom: 25px;

    }

}