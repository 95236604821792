/**
 * .common-panel-connectivitylist-wrapper.
 */

.common-panel-connectivitylist-wrapper {

    /**
     * .heading.
     */

    .heading {

        /**
         * Margin.
         */

        margin-bottom: 15px;

        /**
         * Font related.
         */

        text-transform: uppercase;
        font-weight: bold;
        font-size: 16px;
        color: $brand-two;

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * br.
             */

            br {

                /**
                 * Display.
                 */


                display: none;

            }

        }

    }

    /**
     * .col-left.
     */

    .col-left {

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Margin.
             */
        
            margin-bottom: 50px;

        }

        /**
         * .inset.
         */

        .inset {

            /**
             * Position.
             */

            position: relative;

            /**
             * Sizing.
             */

            height: 100%;

            /**
             * 
             */

            .list {

                /**
                 * .list-item.
                 */

                .list-item {

                    /**
                     * List.
                     */

                    list-style: none;

                    /**
                     * Margin.
                     */

                    margin-bottom: 8px;

                    /**
                     * a.
                     */

                    a {
                        
                        /**
                         * Display.
                         */

                        display: inline-block;

                        /**
                         * Position.
                         */

                        position: relative;

                        /**
                         * Font related.
                         */

                        text-transform: uppercase;
                        font-weight: bold;
                        color: $brand-three;

                        /**
                         * &:hover.
                         */

                        &:hover {

                            /**
                             * Font related.
                             */

                            color: $brand-two;

                            /**
                             * svg.
                             */

                            svg {

                                /**
                                 * path.
                                 */

                                path {

                                    /**
                                     * Fill.
                                     */

                                    fill: $brand-two;

                                }

                            }

                        }

                        /**
                         * svg, img.
                         */

                        svg, img {

                            /**
                             * Display.
                             */

                            display: block;

                            /**
                             * Overflow.
                             */

                            overflow: hidden;

                            /**
                             * Position.
                             */

                            position: absolute;
                            right: -25px;
                            top: 50%;

                            /**
                             * Sizing.
                             */

                            height: 15px;
                            width: 14px;

                            /**
                             * Transform.
                             */

                            transform: translateY(-50%);

                        }

                    }

                }

            }

            /**
             * &:after.
             */

            &:after {

                /**
                 * Content.
                 */

                content: '';

                /**
                 * Position.
                 */

                position: absolute;
                top: 0;
                right: 0;

                /**
                 * Sizing.
                 */

                height: 100%;
                width: 1px;

                /**
                 * Background.
                 */

                background-color: $brand-two;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-mobile-landscape) {

                    /**
                     * Display.
                     */
                
                    display: none;

                }

            }

        }

    }

    /**
     * .col-right.
     */

    .col-right {

        /**
         * .heading.
         */

        .heading {

            /**
             * Margin.
             */

            margin-bottom: 25px;

        }

        /**
         * .common-panel-ctablock-wrapper.
         */

        .common-panel-ctablock-wrapper {

            /**
             * Margin.
             */

            margin-top: 100px;

            /**
             * .col-button.
             */

            .col-button {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                align-items: center;
                justify-content: center;

                /**
                 * @include.
                 */

                @include breakpoint($b-df-tablet) {

                    /**
                     * Padding.
                     */

                    padding-top: 15px;

                    /**
                     * Flex.
                     */
                
                    align-items: flex-start;
                    justify-content: flex-start;

                }

            }

        }

        /**
         * > .inset.
         */

        > .inset {

            /**
             * .technology-items.
             */

            .technology-items {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                justify-content: space-between;
                flex-wrap: wrap;

                /**
                 * .technology-item.
                 */

                .technology-item {

                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * .link.
                         */

                        .link {

                            /**
                             * Font related.
                             */

                            color: $brand-one;

                            /**
                             * Background.
                             */

                            background-color: $brand-two;

                        }

                    }

                    /**
                     * .inset.
                     */

                    .inset {

                        /**
                         * Sizing.
                         */

                        height: 100%;

                        /**
                         * .content.
                         */

                        .content {

                            /**
                             * Sizing.
                             */

                            height: 100%;

                            /**
                             * Display.
                             */

                            display: flex;

                            /**
                             * Flex.
                             */

                            flex-direction: column;
                            justify-content: space-between;

                            /**
                             * .info-container..
                             */

                            .info-container {

                                /**
                                 * Flex.
                                 */

                                flex-grow: 1;

                            }

                        }

                    }

                    /**
                     * Flex.
                     */

                    flex-basis: 31%;

                    /**
                     * @include.
                     */

                    @include breakpoint($b-df-tablet) {

                        /**
                         * 
                         */

                        flex-basis: 100%;

                    }                    

                    /**
                     * Margin.
                     */

                    margin-bottom: 25px;

                    /**
                     * .logo-container.
                     */

                    .logo-container {

                        /**
                         * Background.
                         */

                        background-color: $brand-three;

                        /**
                         * Position.
                         */

                        position: relative;

                        /**
                         * Sizing.
                         */

                        height: 140px;

                        /**
                         * Font related.
                         */

                        text-align: center;

                        /**
                         * img.
                         */

                        img {

                            /**
                             * Position.
                             */

                            position: absolute;
                            top: 50%;
                            left: 50%;

                            /**
                             * Transform.
                             */

                            transform: translateX(-50%) translateY(-50%);

                            /**
                             * Sizing.
                             */

                            max-width: 100px;
                            max-height: 100px;

                        }

                    }

                    /**
                     * .info-container.
                     */

                    .info-container {

                        /**
                         * Background.
                         */

                        background-color: $brand-three;

                        /**
                         * Padding.
                         */

                        padding-bottom: 18px;
                        padding-left: 15px;
                        padding-right: 15px;

                        /**
                         * p.
                         */

                        p {

                            /**
                             * Font related.
                             */

                            color: $brand-one;
                            font-size: 14px;
                            line-height: 19px;
                            text-align: center;

                            /**
                             * &:last-child.
                             */

                            &:last-child {

                                /**
                                 * Margin.
                                 */

                                margin-bottom: 0;

                            }

                        }

                        /**
                         * p.name.
                         */

                        p.name {

                            /**
                             * Font related.
                             */

                            font-weight: bold;
                            font-size: 15px;

                        }

                    }

                    /**
                     * .link.
                     */

                    .link {
                        
                        /**
                         * Display.
                         */
                        
                        display: block;
                        
                        /**
                         * Padding.
                         */
                        
                        padding: 17px 10px 17px 10px;
                        
                        /**
                         * Margin.
                         */

                        margin-bottom: 0;

                        /**
                         * Border.
                         */

                        border: 2px solid $brand-two;
                        border-top: 0;

                        /**
                         * Background.
                         */

                        background-color: $brand-one;


                        /**
                         * Font related.
                         */
                        
                        text-align: center;
                        text-transform: uppercase;
                        font-size: 13px;
                        letter-spacing: 2px;
                        color: $brand-three;

                    }

                }

            }

        }

    }

}

