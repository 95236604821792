/**
 * .mobile-nav-wrapper.
 */

.mobile-nav-wrapper {

    /**
     * Opacity.
     */

    opacity: 0;

    /**
     * Pointer.
     */

    pointer-events: none;

    /**
     * Position.
     */

    position: fixed;
    width: 100%;
    height: 100%;

    /**
     * Transition.
     */

    transition: opacity 0.2s ease;

    /**
     * &.show.
     */

    &.show {

        /**
         * Opacity.
         */

        opacity: 1;

        /**
         * Pointer.
         */

        pointer-events: all;

    }

    /**
     * Z-index.
     */

    z-index: 200;

    /**
     * .mobile-nav-container.
     */

    .mobile-nav-container {

        /**
         * Background,.
         */
        
        background-color: $brand-one;

        /**
         * Padding.
         */

        padding-top: 50px;

        /**
         * Display.
         */

        display: flex;

        /**
         * Sizing.
         */

        height: 100%;
        width: 100%;

        /**
         * Flex.
         */

        justify-content: flex-start;
        align-items: flex-start;

        /**
         * .close-button.
         */

        .close-button {
            
            /**
             * Sizing.
             */

            width: 30px;
            height: 30px;

            /**
             * Position.
             */

            position: absolute;
            top: 20px;
            right: 20px;

            /**
             * 
             */

            svg {
            
                /**
                 * polygon.
                 */

                polygon {

                    /**
                     * Fill.
                     */

                    fill: $brand-two;

                }

            }

            /**
             * &:hover.
             */

            &:hover {
                
                /**
                 * svg.
                 */

                svg {
    
                    /**
                     * polygon.
                     */

                    polygon {

                        /**
                         * Fill.
                         */

                        fill: $brand-three;

                    }

                }

            }

        }

        /**
         * ul.main-menu.
         */

        ul.main-menu {

            /**
             * Margin.
             */

            margin-left: 50px;

            /**
             * > li.
             */

            > li {

                /**
                 * Font related.
                 */

                font-size: 18px;

            }

            /**
             * li.
             */

            li {

                /**
                 * List.
                 */

                list-style: none;

                /**
                 * Margin.
                 */
            
                margin-bottom: 5px;

                /**
                 * a.
                 */

                a {

                    /**
                     * Position.
                     */

                    position: relative;

                    /**
                     * Font related.
                     */

                    color: $brand-three;
                    text-transform: uppercase;
                    font-weight: bold;

                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * Font related.
                         */

                        color: $brand-two;

                    }

                }

                /**
                 * &.has-active-sub-menu-item.
                 */

                &.has-active-sub-menu-item {

                    /**
                     * > a.
                     */

                    > a {

                        /**
                         * Position.
                         */

                        position: relative;

                        /**
                         * &::before.
                         */
            
                        &::before {
            
                            /**
                             * Content.
                             */
            
                            content: '+';
            
                            /**
                             * Font related.
                             */
            
                            font-weight: bold;
                            color: $brand-two;

                            /**
                             * Position.
                             */

                            position: absolute;
                            left: -11px;

                            /**
                             * Transform.
                             */

                            transform: translateY(-1px);

                            /**
                             * Margin.
                             */
            
                            margin-right: 2px;
            
                        }

                    }

                }

                /**
                 * .active.
                 */

                .active {

                    /**
                     * > a.
                     */

                    > a {

                        /**
                         * Font related.
                         */

                        color: $brand-two;

                    }

                }

                /**
                 * ul.
                 */

                ul {

                    /**
                     * Margin.
                     */

                    margin-top: 5px;
                    margin-left: 10px;
                    margin-bottom: 25px;

                }

            }

        }

        /**
         * .menu-mobile-menu-container.
         */

        .menu-mobile-menu-container {

            /**
             * 
             */

            // background-color: palegreen;

            /**
             * Paddding.
             */

            padding-top: 20px;
            padding-left: 40px;

            /**
             * ul#3emenu-mobile-menu.
             */

            ul#menu-mobile-menu {

                // background-color: palevioletred;

                /**
                 * Sizing.
                 */

                width: 100%;

            }

            /**
             * ul.
             */

            ul {

                /**
                 * li.
                 */

                li {

                    /**
                     * Font related.
                     */

                    font-size: 17px;
                    line-height: 26px;
                    text-transform: uppercase;
                    font-weight: bold;

                    /**
                     * List.
                     */

                    list-style: none;

                    /**
                     * Margin.
                     */

                    margin-left: 0;

                    /**
                     * Padding.
                     */

                    // padding-bottom: 10px;
                
                    /**
                     * a.
                     */

                    a {

                        /**
                         * Font related.
                         */

                        color: white;

                        /**
                         * Display.
                         */

                        display: inline-block;

                        /**
                         * 
                         */

                        margin-bottom: 10px;

                        /**
                         * &.subnav-open.
                         */

                        &.subnav-open {

                            /**
                             * &::after.
                             */

                            &::after {

                                /**
                                 * Transform.
                                 */

                                transform: translateY(-50%) rotate(-90deg) !important;

                            }

                        }

                    }

                    /**
                     * &.menu-item-has-children.
                     */

                    &.menu-item-has-children,
                    &.has-subnav {

                        /**
                         * > a.
                         */

                        > a {

                            /**
                             * Position.
                             */

                            position: relative;

                            /**
                             * Display.
                             */

                            display: inline-block;

                            /**
                             * Margin.
                             */

                            // margin-bottom: 10px;

                            // background-color: palegreen;

                            /**
                             * 
                             */

                            // background-color: red !important;

                            /**
                             * &::after.
                             */

                            &::after {

                                /**
                                 * Content.
                                 */

                                content: '';

                                /**
                                 * Background.
                                 */

                                background-image: url(/assets/svg/common/arrow-down.svg);
                                background-repeat: no-repeat;
                                background-size: contain;

                                /**
                                 * Sizing.
                                 */

                                width: 18px;
                                height: 12px;

                                /**
                                 * Position.
                                 */

                                position: absolute;
                                right: -26px;
                                top: 50%;

                                /**
                                 * Transform.
                                 */

                                transform: translateY(-50%);

                                /**
                                 * 
                                 */

                                transition: transform 0.2s ease;

                            }

                        }

                    }

                }

            }

            /**
             * .sub-menu.
             */
        
            .sub-menu {

                /**
                 * Margin.
                 */

                margin-left: 20px;

            }

        }

    }

    /**
     * .menu-container.
     */

    .menu-container {
        
        /**
         * Sizing.
         */

        width: 100%;

    }

}
