/**
 * .casestudies-panel-clientquote-wrapper.
 */

.casestudies-panel-clientquote-wrapper {

    /**
     * .col-logo.
     */

    .col-logo {

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Margin.
             */

            margin-bottom: 20px;
            
        }

        /**
         * img.
         */

        img {

            /**
             * 
             */

            max-width: 80%;

        }

    }

    /**
     * .col-text.
     */

    .col-text {

        /**
         * .quote-wrapper.
         */

        .quote-wrapper {

            /**
             * p.
             */

            p {

                /**
                 * Font related.
                 */

                font-size: 20px;
                font-style: italic;
                line-height: 25px;

            }

        }

        /**
         * p.author.
         */

        p.author {

            /**
             * Font related.
             */

            font-weight: bold;
            font-size: 16px;
            color: $brand-two;

        }


    }

}
