/**
 * .common-panel-relatedcasestudies-wrapper.
 */

.common-panel-relatedcasestudies-wrapper {

    /**
     * .col-casestudy.
     */

    .col-casestudy {

        /**
         * Margin.
         */

        margin-bottom: 30px;

        /**
         * a.
         */

        a {

            /**
             * Display.
             */

            display: block;

            /**
             * Position.
             */

            position: relative;

            /**
             * Overflow.
             */

            overflow: hidden;

            /**
             * Sizing.
             */

            height: 100%;

            /**
             * Border.
             */

            border: 2px solid $brand-two;

        }

        /**
         * .image-container.
         */

        .image-container {

            /**
             * Sizing.
             */

            height: 200px;

            /**
             * Background.
             */

            background-position: center;
            background-size: cover;

            /**
             * img.
             */

            img {

                /**
                 * Display.
                 */

                display: none;

            }

        }

        /**
         * .inset.
         */

        .inset {

            /**
             * Padding.
             */

            padding: 30px 25px 20px 25px;

            /**
             * Overflow.
             */

            overflow: hidden;

            /**
             * Sizing.
             */

            height: 100%;

        }

        /**
         * .heading.
         */

        .heading {

            /**
             * Font related.
             */

            font-size: 24px;
            font-weight: bold;

        }

        /**
         * .date.
         */

        .date {

            /**
             * Font related.
             */

            font-size: 16px;
            font-weight: bold;
            color: $brand-two;

        }

        /**
         * .read-more-container.
         */
        
        .read-more-container {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            flex-direction: column;
            align-items: flex-start;

            /**
             * Position.
             */

            position: absolute;
            left: 0;
            top: 0;

            /**
             * Padding.
             */

            padding: 30px 20px 20px 20px;

            /**
             * Transform.
             */

            transform: translateY(100%);

            /**
             * Sizing.
             */

            height: 100%;
            width: 100%;

            /**
             * Background.
             */

            background-color: $brand-two;

            /**
             * Transition.
             */

            transition: transform 0.5s ease;

            /**
             * .teaser.
             */

            .teaser {

                /**
                 * Font related.
                 */

                color: $brand-one;
                font-size: 14px;

            }

        }

        /**
         * &:hover.
         */

        &:hover {

            /**
             * .read-more-container.
             */

            .read-more-container {

                /**
                 * Transform.
                 */

                transform: translateY(0);

            }

        }

    }

}
