/**
 * .common-panel-two-column-content-wrapper.
 */

.common-panel-two-column-content-wrapper {

    /**
     * .row-content.
     */

    .row-content {

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * 
             */

        }        

    }

    /**
     * .col-left.
     */

    .col-left {

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Padding.
             */

            padding-bottom: 25px;

        }  

    }

    /**
     * .col-right.
     */

    .col-right {

        /**
         * 
         */

    }

}
