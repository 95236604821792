/**
 * .common-panel-relatednews-wrapper.
 */

.common-panel-relatednews-wrapper {

    /**
     * .common-panel-relatednews-container
     */

    .common-panel-relatednews-container {

        /**
         * .heading.
         */

        .heading {

            /**
             * Font related.
             */

            color: $brand-two;

        }

    }

}