/**
 * .careers-item-wrapper.
 */

.careers-item-wrapper {

    /**
     * .form-wrapper.
     */

    .form-wrapper {

        /**
         * Padding.
         */

        padding-top: 60px;

        /**
         * .heading.
         */

        .heading {

            /**
             * Font related.
             */

            color: $brand-two;

        }

    }

    /**
     * .wpforms-container.
     */

    .wpforms-container {

        /**
         * .wpforms-confirmation-container-full.
         */

        .wpforms-confirmation-container-full {
            
            /**
             * Border.
             */

            border: 0;

            /**
             * Background.
             */

            background-color: $brand-two;

            /**
             * p.
             */

            p {

                /**
                 * Font related.
                 */
    
                color: black;
                font-weight: bold;

            }

        }

        /**
         * .wpforms-submit.
         */

        .wpforms-submit {

            /**
             * Margin.
             */

            margin-top: 10px;

            /**
             * Border.
             */

            border: 1px solid $brand-two !important;

            /**
             * Background.
             */

            background-color: $brand-one !important;

            /**
             * Font related.
             */

            color: white !important;
            text-transform: uppercase;
            letter-spacing: 0.1rem;

            /**
             * Padding.
             */

            padding: 15px 30px 15px 30px !important;

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * Background.
                 */

                background-color: $brand-two !important;

                /**
                 * Font related.
                 */

                color: $brand-one !important;

            }

        }

        /**
         * .wpforms-required-label.
         */

        .wpforms-required-label {

            /**
             * Font related.
             */

            color: $brand-three !important;

        }

        /**
         * label.wpforms-error.
         */

        label.wpforms-error {

            /**
             * Padding.
             */

            padding-top: 5px;

            /**
             * Font related.
             */

            color: $brand-two !important;

        }
        
        /**
         * .dz-complete.
         */

        .dz-complete {

            /**
             * Padding.
             */

            padding-bottom: 0 !important;

            /**
             * Border.
             */
        
            border-bottom: 0;

        }

        /**
         * .wpforms-layout-column-50.
         */

        .wpforms-layout-column-50 {

            /**
             * &:last-child.
             */

            &:last-child {

                /**
                 * .wpforms-field-textarea.
                 */

                .wpforms-field-textarea {

                    /**
                     * Padding.
                     */

                    padding-top: 10px;
                    padding-bottom: 10px;
                    
                }

            }

            /**
             * .wpforms-field-textarea.
             */

            .wpforms-field-textarea {

                /**
                 * Padding.
                 */

                padding: 0;

                /**
                 * Sizing.
                 */

                height: 100%;

                /**
                 * textarea.
                 */

                textarea {

                    /**
                     * Sizing.
                     */

                    height: 100%;

                }

            }

        }

        /**
         * .wpforms-field-file-upload.
         */

        .wpforms-field-file-upload {

            /**
             * > label.
             */

            > label {

                /**
                 * Margin.
                 */

                margin-bottom: 10px;

                /**
                 * Font related.
                 */

                color: white;

            }

        }

        /**
         * .wpforms-uploader.
         */

        .wpforms-uploader {

            /**
             * Padding.
             */

            padding: 10px 20px 10px 20px;

        }

        /**
         * .dz-message.
         */

        .dz-message {
            
            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            flex-direction: row !important;

            /**
             * svg.
             */

            svg {

                /**
                 * Margin.
                 */

                margin-right: 10px;

            }


        }

        /**
         * textarea.
         */

        textarea {

            /** 
             * Padding.
             */

            padding-top: 10px !important;

        }

        /**
         * input[type="text"],
         * input[type="email"] .
         */

        input[type="text"],
        input[type="email"] {

            /** 
             * Padding.
             */

            padding-top: 20px !important;
            padding-bottom: 20px !important;

        }

    }

}
