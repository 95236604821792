/**
 * .common-panel-ctablock-wrapper.
 */

.common-panel-ctablock-wrapper {

    /**
     * .inset.
     */

    .inset {

        /**
         * Padding.
         */

        padding: 60px 40px 55px 40px;

        /**
         * Border.
         */

        border: 1px solid $brand-two;

    }

    /*
     * .row. 
     */

    .row {

        /**
         * .col-button.
         */

        .col-button {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            align-items: center;
            justify-content: center;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Padding.
                 */

                padding-top: 15px;

                /**
                 * Flex.
                 */
            
                align-items: flex-start;
                justify-content: flex-start;

            }

        }

        /**
         * .heading.
         */

        .heading {

            /**
             * Font related.
             */

            font-size: 36px;
            font-weight: bold;
            line-height: 42px;

            /**
             * Margin.
             */
        
            margin-bottom: 10px;

        }

    }

}