/**
 * header.header.
 */

header.header {

    /**
     * Position.
     */

    position: absolute;

    /**
     * Sizing.
     */

    width: 100%;

    /**
     * Padding.
     */

    padding-top: 40px;
    padding-bottom: 20px;

    /**
     * Z-index.
     */

    z-index: 1;

    /**
     * .col-menu.
     */

    .col-menu {

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        justify-content: flex-end;
        align-items: flex-end;

    }

    /**
     * .hamburger.
     */

    .hamburger {

        /**
         * Display.
         */

        display: none;

        /**
         * Margin.
         */

        margin-left: 20px;

        /**
         * @include.
         */

        @include breakpoint($b-df-tablet) {

            /**
             * Display. 
             */

            display: block;

        }

    }

    /**
     * .menu-container.
     */

    .menu-container {

        /**
         * Display.
         */

        display: none;     

        /**
         * @include.
         */

        @include breakpoint($b-desktop) {

            /**
             * Display.
             */

            display: block;

        }

    }

    /**
     * .social-icons-list.
     */

    .social-icons-list {

        /**
         * Margin.
         */

        margin-left: 10px;
        
    }

    /**
     * #menu-item-3613.
     */

    #menu-item-3613 {
        
        /**
         * ul.sub-menu.
         */

        ul.sub-menu {

            /**
             * Padding.
             */

            padding-left: 0px !important;

        }

    }

    /**
     * .main-menu-wrapper.
     */

    .main-menu-wrapper {

        /**
         * ul.
         */

        ul {

            /**
             * li.
             */

            li {

                /**
                 * Display.
                 */

                display: inline-block;

                /**
                 * Margin.
                 */

                margin-right: 10px;

                /**
                 * a.
                 */

                a {

                    /**
                     * Font related.
                     */

                    font-weight: bold;
                    color: $brand-three;
                    text-transform: uppercase;

                    /**
                     * Display.
                     */

                    display: inline-block;

                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * Font related.
                         */

                        color: $brand-two;

                    }

                }

                /**
                 * &.dropdown-active.
                 */

                &.dropdown-active {

                    /**
                     * a.
                     */

                    a {

                        /**
                         * Font related.
                         */

                        color: $brand-two;
                                        
                    }

                }

                /**
                 * &.has-subnav,
                 * &.menu-item-has-children.
                 */

                &[data-sub-nav-ref='what-we-do'],
                &[data-sub-nav-ref='about'] {

                    /**
                     * a.
                     */

                    a {
                     
                        /**
                         * Position.
                         */

                        position: relative;

                        /**
                         * Padding.
                         */

                        padding-right: 20px;

                        /**
                         * &::after.
                         */

                        &::after {

                            /**
                             * Content.
                             */

                            content: '';

                            /**
                             * Background.
                             */

                            background-image: url(/assets/svg/common/arrow-down.svg);
                            background-repeat: no-repeat;
                            background-size: contain;

                            /**
                             * Sizing.
                             */

                            width: 15px;
                            height: 10px;

                            /**
                             * Position.
                             */

                            position: absolute;
                            right: 0;
                            top: 50%;

                            /**
                             * Transform.
                             */

                            transform: translateY(-50%);

                        }

                        /**
                         * &:hover.
                         */

                        &:hover {

                            /**
                             * &::after.
                             */

                            &::after {

                                /**
                                 * Backgronud.
                                 */

                                background-image: url(/assets/svg/common/arrow-down-active.svg);

                            }

                        }

                    }

                    /**
                     * &.dropdown-active.
                     */

                    &.dropdown-active {

                        /**
                         * a.
                         */

                        a {

                            /**
                             * &::after.
                             */
    
                            &::after {
    
                                /**
                                 * Backgronud.
                                 */
    
                                background-image: url(/assets/svg/common/arrow-down-active.svg);
    
                            }

                        }

                    }

                }
                
            }

        }

    }


    /**
     * #menu-main-menu.
     */

    #menu-main-menu {

        /**
         * li.
         */

        > li {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * Margin.
             */

            margin-right: 18px;

            /**
             * Position.
             */

            position: relative;

            /**
             * Z-index.
             */

            z-index: 2;
            
            /**
             * &.has-subnav,
             * &.menu-item-has-children.
             */

            &.has-subnav,
            &.menu-item-has-children {

                /**
                 * Position.
                 */

                position: relative;

                /**
                 * Padding.
                 */

                padding-right: 20px;

                /**
                 * &::after.
                 */

                &::after {

                    /**
                     * Content.
                     */

                    content: '';

                    /**
                     * Background.
                     */

                    background-image: url(/assets/svg/common/arrow-down.svg);
                    background-repeat: no-repeat;
                    background-size: contain;

                    /**
                     * Sizing.
                     */

                    width: 15px;
                    height: 10px;

                    /**
                     * Position.
                     */

                    position: absolute;
                    right: 0;
                    top: 50%;

                    /**
                     * Transform.
                     */

                    transform: translateY(-50%);

                }

            }

            /**
             * 
             */

            &.current_page_item,
            &.has-active-sub-menu-item {
                
                /**
                 * &::before.
                 */
    
                &::before {
    
                    /**
                     * Content.
                     */
    
                    content: '+';
    
                    /**
                     * Font related.
                     */
    
                    font-weight: bold;
                    color: $brand-two;
    
                    /**
                     * Margin.
                     */
    
                    margin-right: 2px;
    
                }

                a {

                    color: $brand-two;

                }

                /**
                 * &::after.
                 */

                &::after {

                    /**
                     * Content.
                     */

                    content: '';

                    /**
                     * Background.
                     */

                    background-image: url(/assets/svg/common/arrow-down-active.svg);
                    
                }

            }

            /**
             * Cursor.
             */

            cursor: pointer;

            /**
             * a.
             */

            a {

                /**
                 * Font related.
                 */

                font-weight: bold;
                color: $brand-three;
                text-transform: uppercase;

                /**
                 * Display.
                 */

                display: inline-block;

                /**
                 * &:hover.
                 */

                &:hover {

                    /**
                     * Font related.
                     */

                    color: $brand-two;

                }

            }

            /**
             * &.case-studies.
             */

            &.case-studies {

                /**
                 * ul.sub-menu.
                 */

                ul.sub-menu {

                    /**
                     * Sizing.
                     */

                    width: 250px;

                    /**
                     * Display.
                     */

                    display: flex;

                    /**
                     * Flex.
                     */

                    flex-wrap: wrap;

                    /**
                     * Padding.
                     */

                    padding-left: 0px;

                    /**
                     * li.
                     */

                    li {

                        /**
                         * Flex.
                         */

                        width: 50%;

                    }

                }

            }

            /**
             * ul.sub-menu.
             */

            ul.sub-menu {

                /**
                 * Sizing.
                 */

                width: 280%;

                /**
                 * Position.
                 */

                position: absolute;
                top: 29px;
                left: 0;

                /**
                 * Opacity.
                 */

                opacity: 0;

                /**
                 * Pointer.
                 */

                pointer-events: none;

                /**
                 * Transition.
                 */

                transition: opacity 0.2s ease;

                /**
                 * Z-index.
                 */

                z-index: 200;

                /**
                 * Display.
                 */

                display: block;

                /**
                 * Padding.
                 */

                padding-left: 10px;
                padding-right: 10px;

                /**
                 * Background.
                 */

                background-color: black;
                
                /**
                 * li.
                 */

                li {

                    /**
                     * Margin.
                     */

                    margin-bottom: 7px;

                    /**
                     * Display.
                     */

                    display: block;

                    /**
                     * &.active.
                     */

                    &.active {

                        /**
                         * a.
                         */

                        a {

                            /**
                             * Font related.
                             */

                            color: $brand-two;

                            /**
                             * &::after.
                             */

                            &::after {

                                /**
                                 * Background.
                                 */

                                background-image: url(/assets/svg/common/arrow-right-active.svg);

                            }

                        }

                    }

                    /**
                     * a.
                     */

                    a {

                        /**
                         * Position.
                         */

                        position: relative;

                        /**
                         * Font related.
                         */

                        color: $brand-three;

                        /**
                         * &:hover.
                         */

                        &:hover {

                            /**
                             * Font related.
                             */

                            color: $brand-two;

                            /**
                             * &::after.
                             */

                            &::after {

                                /**
                                 * Background.
                                 */

                                background-image: url(/assets/svg/common/arrow-right-active.svg);

                            }

                        }

                        /**
                         * &::after.
                         */

                        &::after {

                            /**
                             * Content.
                             */

                            content: '';

                            /**
                             * Background.
                             */

                            background-image: url(/assets/svg/common/arrow-right.svg);
                            background-repeat: no-repeat;
                            background-size: contain;

                            /**
                             * Sizing.
                             */

                            width: 10px;
                            height: 12px;

                            /**
                             * Position.
                             */

                            position: absolute;
                            right: -15px;
                            top: 50%;

                            /**
                             * Transform.
                             */

                            transform: translateY(-50%);
                            
                        }

                    }

                }

                /**
                 * &::before.
                 */

                &::before {

                    /**
                     * Content.
                     */

                    content: '';

                    /**
                     * Position.
                     */

                    position: absolute;
                    top: -29px;
                    left: 0;

                    /**
                     * Sizing.
                     */

                    height: 30px;
                    width: 100%;

                    /**
                     * 
                     */

                    z-index: 1;

                }

            }

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * ul.sub-menu.
                 */

                ul.sub-menu {

                    /**
                     * Margin.
                     */

                    margin: 0;

                    /**
                     * Padding.
                     */

                    // padding: 0;

                    /**
                     * Opacity.
                     */

                    opacity: 1;

                    /**
                     * Pointer.
                     */

                    pointer-events: all;

                }

                /**
                 * a.
                 */

                a {

                    /**
                     * Font related.
                     */

                    color: $brand-two;

                }

                &::after {

                    background-image: url(/assets/svg/common/arrow-down-active.svg);

                }

            }

        }

    }

}
