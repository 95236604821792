/**
 * body.
 */

body {

    /**
     * Font related.
     */

}

/**
 * h1.
 */

h1 {

    /**
     * Font related.
     */

    font-size: 40px;
    font-weight: bold;    
    color: $brand-two;

    /**
     * Margin.
     */

    margin-bottom: 15px;

}

/**
 * h2.
 */

h2 {

    /**
     * Margin.
     */

    margin-bottom: 30px;

    /**
     * Font related.
     */

    font-size: 35px;
    font-weight: bold;
    color: $brand-three;
    
}

/**
 * h3.
 */

h3 {

    /**
     * Margin.
     */

    margin-bottom: 16px;

    /**
     * Font related.
     */

    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    color: $brand-two;
    
}

/**
 * h4.
 */

h4 {

    /**
     * Margin.
     */

    margin-bottom: 10px;

    /**
     * Font related.
     */

    font-size: 20px;
    font-weight: bold;
    
}

/**
 * h5.
 */

h5 {

    /**
     * Margin.
     */

    margin-bottom: 6px;

    /**
     * Font related.
     */

    font-size: 16px;
    font-weight: bold;
    
}

/**
 * p.
 */

p {

    /**
     * Margin.
     */

    margin-bottom: 10px;

    /**
     * Font related.
     */

    font-size: 14px;
    line-height: 21px;
    color: $brand-three;

}

/**
 * ul.
 */

ul {

    /**
     * li.
     */

    li {

        /**
         * Font related.
         */

        font-size: 14px;
        line-height: 19px;
        color: $brand-three;
        
        /**
         * List.
         */

        list-style-type: disc;

    }

}

/**
 * a.
 */

a {

    /**
     * Font related.
     */

    color: $brand-two;

    /**
     * &:hover.
     */

    &:hover {

        /**
         * Font related.
         */

        color: $brand-three;
        text-decoration: none;

    }

}

/**
 * 
 */

.text-align-left { text-align: left; }
.text-align-center { text-align: center; }
.text-align-right { text-align: right; }