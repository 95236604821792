/**
 * .common-panel-gallery-wrapper.
 */

.common-panel-gallery-wrapper {
    
    /**
     * h2.heading.
     */

    h2.heading {

        /**
         * Font related.
         */

        color: $brand-two;
        
    }

    /**
     * #main-carousel.
     */

    #main-carousel {

        /**
         * Margin.
         */

        margin-top: 20px;

        /**
         * .splide__slide img.
         */
    
        .splide__slide img {

            /**
             * Sizing.
             */

            width: 100%;
            height: 100%;
            max-height: 650px;

        }

        /**
         * p.caption.
         */

        p.caption {

            /**
             * Position.
             */

            position: absolute;
            bottom: 10px;
            left: 10px;

            /**
             * Margin.
             */

            margin-bottom: 0;

            /**
             * Padding.
             */

            padding-left: 6px;
            padding-right: 6px;

            /**
             * Font related.
             */

            font-size: 13px;
            color: rgba( white, 0.7 );

            /**
             * 
             */

            background-color: rgba( black, 0.7 );

        }

        /**
         * .splide__arrow.splide__arrow--prev,
         * .splide__arrow.splide__arrow--next.
         */
    
        .splide__arrow.splide__arrow--prev,
        .splide__arrow.splide__arrow--next {

            /**
             * Opacity.
             */

            opacity: 1;

            /**
             * Background.
             */

            background-color: black !important;

            /**
             * svg path.
             */

            svg path {

                /**
                 * Fill.
                 */

                fill: $brand-two;

            }

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * Background.
                 */

                background-color: $brand-two !important;

                /**
                 * svg path.
                 */

                svg path {

                    /**
                     * Fill.
                     */

                    fill: black;

                }

            }

            /**
             * 
             */

            &:focus {

                outline: 0 !important;

            }

        }

    }

    /**
     * #thumbnail-carousel.
     */

    #thumbnail-carousel {

        /**
         * Margin.
         */

        margin-top: 10px;
        margin-bottom: 60px;

        /**
         * .splide__slide img.
         */
    
        .splide__slide img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    
        /**
         * .splide__slide.
         */

        .splide__slide {
            opacity: 0.6;
        }
    
        /**
         * .splide__slide.is-active.
         */

        .splide__slide.is-active {
            opacity: 1;
        }

    }

}