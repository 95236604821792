/**
 * 
 */

.contact-panel-details-wrapper,
.contact-details-wrapper {

    /**
     * Padding.
     */

    padding-bottom: 20px;

    /**
     * .col-contactmethods.
     */

    .col-contactmethods {

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Padding.
             */

            padding-top: 20px;

        }

        /**
         * .contact-methods-heading.
         */

        .contact-methods-heading {

            /**
             * Margin.
             */

            margin-bottom: 20px;

            /**
             * Font related.
             */

            font-size: 16px;
            font-weight: bold;
            color: $brand-two;

        }

        /**
         * .contact-method.
         */

        .contact-method {

            /**
             * Margin.
             */

            margin-bottom: 20px;

            /**
             * Display.
             */        

            display: flex;

            /**
             * Flex.
             */

            align-items: center;

            /**
             * a.
             */

            a {

                /**
                 * Font related.
                 */

                font-weight: bold;

            }

            /**
             * .image,
             * object.
             */

            .image,
            object {

                /**
                 * Margin.
                 */

                margin-right: 10px;

                /**
                 * Sizing.
                 */

                width: 75px;

            }

        }

    }

}