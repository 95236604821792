/**
 * footer.
 */

footer {

    /**
     * Background.
     */

    background-image: url(/assets/images/home/header-background.jpg);
    background-size: cover;

    /**
     * Padding.
     */

    padding-top: 50px;
    padding-bottom: 50px;

    /**
     * Overflow.
     */

    overflow: hidden;

    /**
     * .row-one.
     */

    .row-one {

        /**
         * Margin.
         */

        margin-bottom: 90px;

    }

    /**
     * .col-logo.
     */

    .col-logo {

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        align-items: flex-start;

        /**
         * Padding.
         */

        padding-top: 3px;


        /**
         * img.
         */

        img {

            /**
             * Sizing.
             */

            width: 128px;
            height: auto;

        }

        /**
         * @include.
         */

        @include breakpoint($b-df-tablet) {

            /**
             * Margin.
             */

            margin-bottom: 20px;
            
        }

    }

    /**
     * p.message.
     */

    p.message {

        /**
         * Display.
         */

        display: inline;

        /**
         * Font related.
         */

        color: rgba($brand-three, 0.2);

    }

    /**
     * .col-contact.
     */

    .col-contact {

        /**
         * .heading.
         */

        .heading {

            /**
             * Margin.
             */

            margin-bottom: 0;

        }

        /**
         * .note.
         */

        .note {

            /**
             * Font related.
             */

            font-size: 14px;
            font-weight: bold;

        }

    }

    /**
     * .footer-links.
     */

    .footer-links {

        /**
         * Display.
         */

        display: inline-block;

        /**
         * li.
         */

        li {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * 
             */

            position: relative;

            /**
             * 
             */

            &::before {

                /**
                 * Content.
                 */

                content: '';

                /**
                 * Position.
                 */

                position: absolute;
                left: 0;
                top: 50%;

                /**
                 * Transform.
                 */

                transform: translateY(-50%);

                /**
                 * Sizing.
                 */

                height: 80%;
                width: 1px;

                /**
                 * Background.
                 */

                background-color: rgba($brand-three, 0.2);

            }

            /**
             * Padding.
             */

            padding-left: 5px;

            /**
             * a.
             */

            a {

                /**
                 * Font related.
                 */

                color: rgba($brand-three, 0.2);

                /**
                 * &:hover.
                 */

                &:hover {

                    /**
                     * Font related.
                     */

                    color: $brand-two;

                }

            }

        }

    }

    /**
     * #menu-footer-navigation.
     */

    #menu-footer-navigation {

        /**
         * Padding.
         */

        padding-left: 15px;

        /**
         * li.
         */

        li {

            /**
             * List.
             */

            list-style: none;

            /**
             * a.
             */

            a {

                /**
                 * Font related.
                 */

                font-weight: bold;
                color: $brand-three;
                text-transform: uppercase;
                font-size: 13px;
                line-height: 16px;

                /**
                 * &:hover.
                 */

                &:hover {

                    /**
                     * Font related.
                     */

                    color: $brand-two;

                }

            }

        }

    }

    /**
     * .wpforms-container.
     */

    .wpforms-container {

        /**
         * .wpforms-confirmation-container-full.
         */

        .wpforms-confirmation-container-full {

            /**
             * Background.
             */

            background-color: $brand-two !important;

            /**
             * Border.
             */

            border: 0 !important;

            /**
             * p.
             */

            p {

                /**
                 * Font related.
                 */

                color: $brand-one !important;

            }

        }

        /**
         * form.
         */

        form {

            /**
             * Display.
             */

            display: flex;

            /**
             * label.wpforms-error.
             */

            label.wpforms-error {

                /**
                 * Padding.
                 */

                padding-top: 5px;

                /**
                 * Font related.
                 */

                color: $brand-two !important;

            }

            /**
             * .wpforms-field-container.
             */

            .wpforms-field-container {

                /**
                 * Flex.
                 */

                flex-grow: 1;

                /**
                 * input.
                 */

                input {

                    /**
                     * Sizing.
                     */

                    max-width: 100% !important;

                    /**
                     * Border.
                     */

                    border: 0 !important;
                    border-radius: 0 !important;

                    /**
                     * Sizing.
                     */

                    height: 38px;

                    /**
                     * &::placeholder.
                     */

                    &::placeholder {

                        /**
                         * Font related.
                         */

                        font-size: 14px;
                        line-height: 21px;

                    }

                }

            }

            /**
             * .wpforms-submit-container.
             */

            .wpforms-submit-container {

                /**
                 * Sizing.
                 */

                max-width: initial !important;

                /**
                 * button.
                 */

                button {

                    /**
                     * Background.
                     */

                    background-color: $brand-two !important;

                    /**
                     * Border.
                     */

                    border: 0 !important;

                    /**
                     * Position.
                     */

                    position: relative;

                    /**
                     * Sizing.
                     */

                    height: 38px;
                    width: 38px;

                    /**
                     * Font related.
                     */

                    text-indent: -9999px;

                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * &::after.
                         */

                        &::after {

                            /**
                             * Background.
                             */
    
                            background-image: url(/assets/svg/common/form-arrow-active.svg);
        
                        }

                    }

                    /**
                     * &::after.
                     */

                    &::after {

                        /**
                         * Content.
                         */

                        content: '';

                        /**
                         * Position.
                         */

                        position: absolute;
                        top: 50%;
                        left: 50%;

                        /**
                         * Sizing.
                         */

                        width: 25px;
                        height: 25px;

                        /**
                         * Background.
                         */

                        background-image: url(/assets/svg/common/form-arrow.svg);

                        /**
                         * Transform.
                         */

                        transform: translateX(-50%) translateY(-50%);

                    }

                }

            }

        }

    }

}
