/**
 * .contact-offices-wrapper.
 */

.contact-offices-wrapper {

    /**
     * Padding.
     */

    padding-top: 25px;

}

/**
 * .contact-panel-offices-wrapper.
 */

.contact-panel-offices-wrapper,
.contact-offices-wrapper {

    /**
     * .row-graphic.
     */

    .row-graphic {

        /**
         * Padding.
         */

        padding-top: 20px;

        /**
         * Margin.
         */

        margin-bottom: 50px;

        /**
         * Font related.
         */

        text-align: center;

        /**
         * img.
         */

        img {

            width: 100%;

        }

    }

    /**
     * .col-address.
     */

    .col-address {

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Margin.
             */

            margin-bottom: 25px;

        }

        /**
         * .title.
         */

        .title {

            /**
             * Margin.
             */

            margin-bottom: 15px;

            /**
             * Font related.
             */
        
            font-size: 18px;
            color: $brand-two;

        }

        /**
         * .address-list.
         */

        .address-list {

            /**
             * Margin.
             */

            margin-bottom: 15px;

            /**
             * li.
             */

            li {

                /**
                 * List.
                 */

                list-style: none !important;

            }

        }

        /**
         * .number-list.
         */

        .number-list {

            /**
             * Margin.
             */

            margin-bottom: 10px;

            /**
             * li.
             */

            li {

                /**
                 * List.
                 */

                list-style: none !important;

                /**
                 * Font related.
                 */

                font-size: 18px;
                color: $brand-two;

            }

        }

    }

}