/**
 * .common-panel-keystats-wrapper.
 */

.common-panel-keystats-wrapper {

    /**
     * .row-heading.
     */

    .row-heading {

        /**
         * Font related.
         */

        text-align: center;

        /**
         * Padding.
         */

        padding-bottom: 20px;

        /**
         * p.
         */
        
        p {

            /**
             * Font related.
             */

            font-size: 16px;
            font-weight: bold;
            text-transform: uppercase;

        }

    }    

    /**
     * .col-keystat.
     */

    .col-keystat {

        /**
         * Font related.
         */

        text-align: center;

        /**
         * @include.
         */

        @include breakpoint($b-df-mobile-landscape) {

            /**
             * Margin.
             */

            margin-bottom: 20px;
            
        }

        /**
         * .image,
         * object.
         */

        .image,
        object {

            /**
             * Sizing.
             */

            width: 70px;

            /**
             * Margin.
             */

            margin-bottom: 20px;

        }

        /**
         * .number-and-postfix.
         */

        .number-and-postfix {

            /**
             * Margin.
             */

            margin-bottom: 0;

            /**
             * .number.
             */

            .number {

                /**
                 * Font related.
                 */

                font-size: 45px;
                font-weight: bold;

            }

            /**
             * .postfix.
             */

            .postfix {

                /**
                 * Font related.
                 */

                font-size: 45px;
                font-weight: bold;

            }

        }

        /**
         * .label.
         */

        .label {

            /**
             * Font related.
             */

            font-size: 24px;
            font-weight: bold;
            line-height: 31px;
            color: $brand-two;

        }

    }

}