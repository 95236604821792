/**
 * .casestudies-panel-graphic-container.
 */

.casestudies-panel-graphic-container {

    /**
     * img.
     */

    img {

        /**
         * Sizing.
         */

        max-width: 100%;

    }

}