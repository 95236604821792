html, body { height: 100%;}

/**
 * body.
 */

body {

    /**
     * Background.
     */

    background-color: $brand-one;

    // background-color: brown;

    /**
     * Sizing.
     */

    height: 100%;

}

/**
 * body > footer.
 */

body > footer {

    /**
     * Position.
     */

    position: sticky;
    top: 100vh;

}

/**
 * .do-not-display.
 */

.do-not-display {

    /**
     * Display.
     */

    display: none;

}

/**
 * .h2-reduced-margin-bottom.
 */

.h2-reduced-margin-bottom {

    /**
     * h2.
     */

    h2 {

        /**
         * Margin.
         */

        margin-bottom: 15px;

    }

}

/**
 * .quote-extra-large-text.
 */

.quote-extra-large-text {

    /**
     * p.quote.
     */

    p.quote {

        /**
         * Padding.
         */

        padding-top: 12px;

        /**
         * Font related.
         */

        font-size: 35px !important;
        line-height: 39px !important;

    }

}

/**
 * .social-icons-list.
 */

.social-icons-list {

    /**
     * li.
     */

    li {

        /**
         * Display.
         */

        display: inline-block;

    }

    /**
     * a.
     */

    a {

        /**
         * Display.
         */

        display: inline-block;

        /**
         * img, svg.
         */

        img, svg {

            /**
             * Sizing.
             */

            width: 18px;

            /**
             * Transform.
             */

            transform: translateY(-2px);

        }

        /**
         * svg.
         */

        svg {

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * path.
                 */

                path {
                    
                    /**
                     * Fill.
                     */

                    fill: $brand-three;

                }

            }

        }

    }

}

/**
 * 
 */

.display-none      { display: none; }
.display-block-xs  { @include breakpoint($bs-extra-small-only)  { display: block; } }
.display-block-sm  { @include breakpoint($bs-small-only)        { display: block; } }
.display-block-md  { @include breakpoint($bs-medium-only)       { display: block; } }
.display-block-lg  { @include breakpoint($bs-large-only)        { display: block; } }
.display-block-xl  { @include breakpoint($bs-extra-large-only)  { display: block; } }
.display-block-xxl { @include breakpoint($bs-extra-extra-large-only) { display: block; } }

/**
 * .text-align-center.
 */

.text-align-center {

    /**
     * Font related.
     */

    text-align: center;

}