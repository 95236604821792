/**
 * .news-list.
 */

.news-list {

    /**
     * li.
     */

    li {

        /**
         * List.
         */

        list-style: none !important;

        /**
         * &.related.
         */

        &.related {

            /**
             * Margin.
             */

            margin-bottom: 25px;

        }

        /**
         * &[data-type='casestudies'].
         */

        &[data-type='casestudies'] {

            /**
             * .date.
             */

            .date {

                /**
                 * Display.
                 */

                display: none;

            }

        }

        /**
         * .news-list-item.
         */

        .news-list-item {

            /**
             * Position.
             */

            position: relative;

            /**
             * Overflow.
             */

            overflow: hidden;

            /**
             * Sizing.
             */

            height: 100%;

            /**
             * Display.
             */

            display: block;

            /**
             * Border.
             */

            border: 3px solid $brand-two;

            /**
             * Background.
             */

            background-color: $brand-one;

            /**
             * .inset.
             */

            .inset {

                /**
                 * Padding.
                 */

                padding: 30px 20px 25px 20px;

            }

            /**
             * .image-container.
             */

            .image-container {

                /**
                 * Background.
                 */

                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

                /**
                 * Sizing.
                 */

                height: 200px;

            }

            /**
             * img.
             */

            img {

                /**
                 * Display.
                 */

                display: none;

                /**
                 * Sizing.
                 */

                max-width: 100%;

            }

            /**
             * .type.
             */

            .type {

                /**
                 * Font related.
                 */

                font-weight: bold;
                text-transform: uppercase;
                color: $brand-two;

            }

            /**
             * .title.
             */

            .title {

                /**
                 * Margin.
                 */

                margin-bottom: 15px;

                /**
                 * Font related.
                 */

                font-size: 24px;
                line-height: 30px;
                font-weight: bold;

            }

            /**
             * &.related.
             */

            &.related {

                /**
                 * Margin.
                 */

                margin-bottom: 20px;

                /**
                 * .date.
                 */

                .date {

                    /**
                     * Margin.
                     */

                    margin-bottom: 20px;

                }

            }

            /**
             * .date.
             */

            .date {

                /**
                 * Font related.
                 */

                font-size: 16px;
                font-weight: bold;

            }

            /**
             * .link.
             */

            .link {

                /**
                 * Padding.
                 */

                padding-top: 5px;

                /**
                 * Font related.
                 */

                text-transform: uppercase;
                letter-spacing: 1px;
                color: $brand-one;
                font-size: 12px;

            }

            /**
             * .read-more-container.
             */
            
            .read-more-container {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Flex.
                 */

                flex-direction: column;
                align-items: flex-start;

                /**
                 * Position.
                 */

                position: absolute;
                left: 0;
                top: 0;

                /**
                 * Padding.
                 */

                padding: 30px 20px 20px 20px;

                /**
                 * Transform.
                 */

                transform: translateY(100%);

                /**
                 * Sizing.
                 */

                height: 100%;
                width: 100%;

                /**
                 * Background.
                 */

                background-color: $brand-two;

                /**
                 * Transition.
                 */

                transition: transform 0.5s ease;

                /**
                 * .teaser.
                 */

                .teaser {

                    /**
                     * Font related.
                     */

                    color: $brand-one;
                    font-size: 14px;

                }

            }

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * .read-more-container.
                 */

                .read-more-container {

                    /**
                     * Transform.
                     */

                    transform: translateY(0);

                }

            }

        }
    
    }

}
