/**
 * .news-item-wrapper.
 */

.news-item-wrapper {

    /**
     * 
     */

    margin-bottom: 80px;

    /**
     * .col-related.
     */

    .col-related {

        /**
         * Padding.
         */

        padding-bottom: 60px;

        /**
         * 
         */

        margin-top: -5px;

    }

    /**
     * .col-content.
     */

    .col-content {

        /**
         * ul.
         */

        ul {
            
            /**
             * Margin.
             */

            margin-left: 16px;
            margin-bottom: 10px;

            /**
             * li.
             */

            li {

                /**
                 * Font related.
                 */

                color: $brand-two !important;

            }

        }

    }

    /**
     * .row-hero.
     */

    .row-hero {

        /**
         * Padding.
         */

        padding-top: 210px;
        padding-bottom: 50px;

        /**
         * p.
         */

        p {

            /**
             * Font related.
             */
            
            font-size: 24px;
            line-height: 31px;

        }

        /**
         * .post-details.
         */

        .post-details {

            /**
             * Margin.
             */

            margin-bottom: 40px;

            /**
             * li.
             */

            li {

                position: relative;

                /**
                 * Display.
                 */

                display: inline-block;

                /**
                 * Font related.
                 */

                font-size: 13px;
                text-transform: uppercase;
                font-weight: bold;

                /**
                 * 
                 */

                padding-left: 7px;
                padding-right: 6px;

                /**
                 * &:first-child.
                 */

                &:first-child {

                    /**
                     * Padding.
                     */

                    padding-left: 0;

                    /**
                     * &::before.
                     */

                    &::before {

                        /**
                         * Content.
                         */

                        content: none;

                    }


                }

                /**
                 * 
                 */

                &::before {

                    /**
                     * Content.
                     */

                    content: '';

                    /**
                     * Position.
                     */

                    position: absolute;
                    left: -2px;
                    top: 50%;

                    /**
                     * Transform.
                     */

                    transform: translateY(-50%);

                    /**
                     * Sizing.
                     */

                    height: 65%;
                    width: 2px;

                    /**
                     * Background.
                     */

                    background-color: rgba($brand-two, 1);

                }

            }

        }

    }

}