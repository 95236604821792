/**
 * .careers-aboutmdx-wrapper.
 */

.careers-aboutmdx-wrapper {

    /**
     * .careers-aboutmdx-container.
     */

    .careers-aboutmdx-container {

        /**
         * Margin.
         */

        margin-bottom: 100px;

        /**
         * h2.
         */
    
        h2 {

            /**
             * Font related.
             */

            color: $brand-two;

        }

        /**
         * .inset.
         */

        .inset {

            /**
             * Padding.
             */

            padding: 30px 30px 20px 30px;

            /**
             * Border
             */

            border: 1px solid $brand-two;

            /**
             * p.
             */

            p {

                /**
                 * Padding.
                 */

                font-size: 20px;
                line-height: 26px;

            }

        }

    }

}