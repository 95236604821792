/**
 * .careers-list.
 */

.careers-list {

    /**
     * Margin.
     */

    margin-top: 40px;

    margin-bottom: 60px;

    /**
     * li.
     */

    li {

        /**
         * List.
         */

        list-style: none !important;

        /**
         * Margin.
         */

        margin-bottom: 20px;

        /**
         * &.related.
         */

        &.related {

            /**
             * Margin.
             */

            margin-bottom: 25px;

        }

        /**
         * .careers-list-item.
         */

        .careers-list-item {

            /**
             * Position.
             */

            position: relative;

            /**
             * Overflow.
             */

            overflow: hidden;

            /**
             * Sizing.
             */

            height: 100%;

            /**
             * Display.
             */

            display: block;

            /**
             * Border.
             */

            border: 3px solid $brand-two;

            /**
             * Background.
             */

            background-color: $brand-one;

            /**
             * .inset.
             */

            .inset {

                /**
                 * Padding.
                 */

                padding: 30px 20px 25px 25px;

            }

            .details-list {

                /**
                 * Margin.
                 */

                margin-bottom: 20px;

            }

            /**
             * .title.
             */

            .title {

                /**
                 * Margin.
                 */

                margin-bottom: 15px;

                /**
                 * Font related.
                 */

                font-size: 24px;
                line-height: 30px;
                font-weight: bold;

            }

            /**
             * .date.
             */

            .date {

                /**
                 * Font related.
                 */

                font-size: 16px;
                font-weight: bold;

            }

        }
    
    }

}
