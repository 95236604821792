/**
 * .common-panel-pricesharinglist-wrapper.
 */

.common-panel-pricesharinglist-wrapper {

    /**
     * .common-panel-pricesharinglist-container.
     */

    .common-panel-pricesharinglist-container {

        /**
         * .col-heading.
         */

        .col-heading {

            /**
             * p.heading.
             */

            p.heading {

                /**
                 * Font related.
                 */

                font-size: 26px;
                line-height: 28px;
                font-weight: bold;
                color: $brand-two;

                /**
                 * Margin.
                 */

                margin-bottom: 30px;

            }

        }

        /**
         * .col-iconblock.
         */

        .col-iconblock {

            /**
             * Margin.
             */

            margin-bottom: 40px;

            /**
             * Font related.
             */

            text-align: center;

            /**
             * img, svg, object.
             */

            img, svg, object {

                /**
                 * Sizing.
                 */

                width: 85px;

                /**
                 * Margin.
                 */

                margin-bottom: 5px;

            }

            /**
             * .heading.
             */

            .heading {

                /**
                 * Font related.
                 */

                color: $brand-two;
                font-size: 20px;
                line-height: 26px;
                font-weight: bold;

            }

            /**
             * p.
             */

            p {

                /**
                 * Font related.
                 */

                font-size: 15px;
                line-height: 20px;

            }

            /**
             * .button.
             */

            .button {

                /**
                 * Margin.
                 */

                margin-top: 10px;

            }

            /**
             * > .inset.
             */

            > .inset {

                /**
                 * Display.
                 */

                display: flex;

                /**
                 * Padding.
                 */

                padding-left: 10px;
                padding-right: 10px;

                /**
                 * Font related.
                 */

                text-align: left;

                /**
                 * .icon-container.
                 */

                .icon-container {

                    /**
                     * Margin.
                     */

                    margin-right: 18px;

                    /**
                     * .icon.
                     */

                    .icon {

                    }

                }

            }

        }

    }

}