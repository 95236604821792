/**
 * .sub-nav-background.
 */

.sub-nav-background {

    /**
     * Opacity.
     */

    // opacity: 0;

    /**
     * Pointer.
     */

    pointer-events: none;

    /**
     * Transition.
     */

    transition: opacity 0.2s ease;


    /**
     * Background.
     */

    // background-color: rgba(red, 0.1);
    // background-color: red;

    /**
     * Z-index.
     */

    z-index: 8;

    /**
     * &.show.
     */

    &.show {

        /**
         * Position.
         */

        position: absolute;
        top: 200px;
        right: 0;
        bottom: 0;
        left: 0;

        /**
         * Opacity.
         */

        opacity: 1;

        /**
         * Pointer.
         */

        pointer-events: all;

    }

}

/**
 * .sub-nav-wrapper.
 */

.sub-nav-wrapper {

    /**
     * Position.
     */

    position: absolute;
    top: 160px;

    /**
     * Sizing.
     */

    width: 100%;

    /**
     * Z-index.
     */

    z-index: 10;

    /**
     * Padding.
     */

    padding-top: 30px;
    padding-bottom: 30px;

    /**
     * 
     */

    // background-color: rgba( crimson, 1 );

    /**
     * 
     */

    background-color: black;

    /**
     * .sub-nav-group.what-we-do.
     */

    .sub-nav-group.what-we-do {

        /**
         * Sizing.
         */

        // min-height: 300px;

        /**
         * a.price-sharing.
         */

        a.price-sharing {

            /**
             * Margin.
             */

            margin-bottom: 10px;

            /**
             * Font related.
             */
        
            text-transform: initial;

        }

        /**
         * .heading-col.
         */

        .heading-col {

            /**
             * Margin.
             */

            margin-bottom: 20px;

        }

        /**
         * .heading.active.
         */

        .heading.active {

            /**
             * Font related.
             */

            color: $brand-two;

        }

        /**
         * ul.
         */

        ul {

            /**
             * li.
             */

            li {

                /**
                 * List.
                 */

                list-style: none;

                /**
                 * Margin.
                 */

                margin-bottom: 4px;

                /**
                 * a.
                 */

                a {

                    /**
                     * Font related.
                     */

                    color: white;

                    /**
                     * &:hover.
                     */

                    &:hover {

                        /**
                         * Font related.
                         */

                        color: $brand-two;

                    }

                }

            }

        }

        /**
         * .col-solutions.
         */

        .col-solutions {

            /**
             * ul.
             */

            ul.price-sharing-list {

                /**
                 * Margin.
                 */

                margin-left: 10px;
                margin-bottom: 15px;

            }

            /**
             * ul.solutions.
             */

            ul.solutions {

                /**
                 * Margin.
                 */

                margin-left: 0;

                /**
                 * li.
                 */

                li {

                    /**
                     * a.
                     */
                
                    a {

                        /**
                         * Font related.
                         */

                        font-weight: bold;

                    }

                }

            }

        }

        /**
         * 
         */

        .col-solutions,
        .col-markets {
           
            /**
             * Padding.
             */

            padding-left: 30px;

            /**
             * &::after.
             */

            &::after {

                /**
                 * Content.
                 */

                content: '';

                /**
                 * Position.
                 */

                position: absolute;
                top: 0;
                bottom: 0;
                right: 10px;

                /**
                 * Sizing.
                 */

                width: 1px;

                /**
                 * Background.
                 */

                background-color: rgba( white, 0.2 );

            }

            /**
             * ul.
             */

            ul {

                /**
                 * li.
                 */

                li {

                    /**
                     * &.active.
                     */

                    &.active {

                        /**
                         * a.
                         */

                        a {
                            
                            /**
                             * Font related.
                             */

                            color: $brand-two;

                        }

                    }

                }

            }

        }

        /**
         * .col-connectivity.
         */

        .col-connectivity {

            /**
             * Padding.
             */

            padding-left: 30px;
            
            /**
             * .heading.
             */

            .heading {

                /**
                 * Margin.
                 */

                margin-bottom: 20px;

            }

        }

    }

    /**
     * .sub-nav-group.about.
     */

    .sub-nav-group.about {

        /**
         * .about-menu-wrapper.
         */

        .about-menu-wrapper {

            /**
             * ul.
             */

            ul {               

                /**
                 * li.
                 */

                li {

                    /**
                     * List.
                     */

                    list-style: none;

                    /**
                     * Font related.
                     */

                    text-transform: uppercase;
                    font-weight: bold;

                    /**
                     * Margin.
                     */

                    margin-bottom: 4px;

                    /**
                     * &.active.
                     */

                    &.active {

                        /**
                         * a.
                         */
                    
                        a {

                            /**
                             * Font related.
                             */

                            color: $brand-two;

                        }

                    }

                    /**
                     * a.
                     */

                    a {

                        /**
                         * Font related.
                         */

                        color:white;

                        /**
                         * &:hover.
                         */

                        &:hover {

                            /**
                             * Font related.
                             */

                            color: $brand-two;

                        }

                    }

                }

            }

        }

        /**
         * .col-cta.
         */

        .col-cta {

            /**
             * .inset.
             */

            .inset {

                /**
                 * Sizing.
                 */

                height: 100%;

                /**
                 * .cta-wrapper.
                 */

                .cta-wrapper {

                    /**
                     * Sizing.
                     */

                    height: 100%;

                    /**
                     * Display.
                     */
                
                    display: flex;

                    /**
                     * Flex.
                     */

                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;

                }

            }

            /**
             * &::after.
             */

            &::after {

                /**
                 * Content.
                 */

                content: '';

                /**
                 * Position.
                 */

                position: absolute;
                top: 0;
                bottom: 0;
                right: 10px;

                /**
                 * Sizing.
                 */

                width: 1px;

                /**
                 * Background.
                 */

                background-color: rgba( white, 0.2 );

            }

        }

    }

    /**
     * .sub-nav-group.
     */

    .sub-nav-group {
    
        /**
         * Display.
         */

        display: none;

        /**
         * Opacity.
         */

        opacity: 0;

        /**
         * Transition.
         */

        transition: opacity 0.2s ease;

        /**
         * &.show.
         */

        &.show {

            /**
             * Display.
             */

            display: block;

            /**
             * Opacity.
             */

            opacity: 1;

        }

        /**
         * 
         */

        p.heading,
        a.heading {

            /**
             * 
             */

            display: block;
        
            /**
             * 
             */

            font-size: 14px;
            font-weight: bold;
            color: white;
            text-transform: uppercase;

        }

        /**
         * a.heading.
         */
    
        a.heading {

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * Font related.
                 */

                color: $brand-two;

            }

        }

    }



}