/**
 * .home-panel-animatedgraphic-wrapper.
 */

.home-panel-animatedgraphic-wrapper {

    /**
     * img.
     */

    img {

        /**
         * Sizing.
         */

        max-width: 100%;

    }

    /**
     * .lottie-wrapper.
     */

    .lottie-wrapper {

        /**
         * Position.
         */

        position: relative;

        /**
         * lottie-player.
         */

        lottie-player {

            /**
             * Sizing.
             */        

            width: 100%;

        }

        /**
         * .tooltip-mark.
         */

        .tooltip-mark {

            /**
             * Display.
             */

            display: block;

            /**
             * Sizing.
             */

            width: 22px;
            height: 22px;
            
            /**
             * @include.
             */
            
            @include breakpoint($b-df-tablet) {
                
                /**
                 * Sizing.
                 */
                
                width: 18px;
                height: 18px;
                
            }
            
            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {

                /**
                 * Sizing.
                 */

                width: 14px;
                height: 14px;
                
            }

            /**
             * Position.
             */

            position: absolute;

            /**
             * Border.
             */

            border-radius: 20px;
            border: 0;

            /**
             * Background.
             */

            background-color: white;

            /**
             * Cursor.
             */

            cursor: pointer;

            /**
             * &:hover.
             */

            &:hover {

                /**
                 * Background.
                 */

                background-color: $brand-two;

                /**
                 * svg.
                 */

                svg {

                    /**
                     * path.
                     */

                    path {

                        /**
                         * Fill.
                         */

                        fill: $brand-one;

                    }

                }

            }

            /**
             * svg.
             */

            svg {

                /**
                 * Position.
                 */

                position: absolute;
                top: 50%;
                left: 50%;

                /**
                 * Transform.
                 */

                transform: translateX(-50%) translateY(-50%);

                /**
                 * Sizing.
                 */

                width: 60%;

            }

        }

        /**
         * .popper-tooltip.
         */

        .popper-tooltip {

            /**
             * Opacity.
             */

            opacity: 0;

            /**
             * Transition.
             */

            transition: opacity 0.2s ease;

            /**
             * Pointer.
             */

            pointer-events: none;

            /**
             * &.show.
             */

            &.show {

                /**
                 * Opacity.
                 */

                opacity: 1;

                /**
                 * Pointer.
                 */

                pointer-events: all;

            }

            /**
             * Position.
             */

            position: absolute;

            /**
             * Sizing.
             */

            width: 300px;

            /**
             * Background.
             */

            background-color: $brand-two;

            /**
             * Padding.
             */

            padding: 17px;

            /**
             * Font related.
             */

            font-size: 14px;
            line-height: 17px;

            /**
             * Z-index.
             */

            z-index: 120;

        }

        /**
         * .tooltip-mark__trading-desk.
         */

        .tooltip-mark__trading-desk {

            /**
             * Position.
             */

            top: 15.3%;
            left: 30.9%;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {
                
                /**
                 * Position.
                 */

                top: 16%;
                left: 30.2%;

            }

            /**
             * @include.
             */

            @include breakpoint($b-df-tablet) {
                
                /**
                 * Position.
                 */

                top: 15%;
                left: 30.5%;

            }

        }

        /**
         * .tooltip-mark__client.
         */

        .tooltip-mark__client {

            /**
             * Position.
             */

            top: 34.5%;
            left: 66.6%;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {
                
                /**
                 * Position.
                 */

                top: 39%;
                left: 66.2%;

            }

            /**
             * @include.
             */

            @include breakpoint($b-df-tablet) {
                
                /**
                 * Position.
                 */

                top: 34%;
                left: 66.5%;

            }

        }
        
        /**
         * .tooltip-mark__sales-desk.
         */

        .tooltip-mark__sales-desk {

            /**
             * Position.
             */

            top: 51.1%;
            left: 30.8%;

            /**
             * @include.
             */

            @include breakpoint($b-df-mobile-landscape) {
                
                /**
                 * Position.
                 */

                top: 59%;
                left: 30.5%;

            }

            /**
             * @include.
             */

            @include breakpoint($b-df-tablet) {
                
                /**
                 * Position.
                 */

                top: 50%;
                left: 30.5%;

            }

        }

    }

}
