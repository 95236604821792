/**
 * .common-panel-latestmedia-wrapper.
 */

.common-panel-latestmedia-wrapper {

    /**
     * .col-item.
     */

    .col-item {

        /**
         * Sizing.
         */

        min-height: 200px;

        /**
         * Margin.
         */

        margin-bottom: 25px;

    }

    /**
     * .row-heading.
     */

    .row-heading {

        /**
         * Margin.
         */

        margin-bottom: 40px;

    }

    /**
     * .col-heading.
     */

    .col-heading {

        /**
         * h2.
         */

        h2 {

            /**
             * Margin.
             */

            margin-bottom: 0;

        }        

    }

    /**
     * .col-pagination.
     */

    .col-pagination {

        /**
         * Font related.
         */

        text-align: right;

        /**
         * Display.
         */

        display: flex;

        /**
         * Flex.
         */

        justify-content: flex-end;
        align-items: flex-end;

    }

    /**
     * .pagination-list.
     */

    .pagination-list {

        /**
         * li.
         */

        li {

            /**
             * Display.
             */

            display: inline-block;

            /**
             * Margin.
             */

            margin-right: 10px;

            /**
             * Font related.
             */

            font-weight: bold;
            text-transform: uppercase;
            font-size: 13px;

            /**
             * &:last-child.
             */

            &:last-child {

                /**
                 * Margin.
                 */

                margin-right: 0;

            }

            /**
             * a.
             */

            a {

                /**
                 * Font related.
                 */

                color: $brand-three;

                /**
                 * &:hover,
                 * &.active.
                 */
                
                &:hover,
                &.active {
    
                    /**
                     * Font related.
                     */
                    
                    color: $brand-two;
    
                }

            }

        }

    }

    /**
     * .col-item .list-item.
     */
    
    .col-item .list-item {



    }

}
