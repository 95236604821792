/**
 * .button.
 */

.button {

    /**
     * Display.
     */

    display: inline-block;

    /**
     * Padding.
     */

    padding: 15px;

    /**
     * Border.
     */

    border: 1px solid $brand-two;

    /**
     * Font related.
     */

    font-size: 12px;
    line-height: 18px;
    color: $brand-three;
    text-transform: uppercase;
    letter-spacing: 2px;

    /**
     * &:hover.
     */

    &:hover {

        /**
         * Background.
         */

        background-color: $brand-two;

        /**
         * Font related.
         */

        color: $brand-one;

    }

}