/**
 * .common-panel-graphic-wrapper.
 */

.common-panel-graphic-wrapper {

    /**
     * p.
     */

    p {

        /**
         * Font related.
         */
        
        font-size: 24px;
        line-height: 31px;
        text-align: center;

        /**
         * Margin.
         */

        margin-bottom: 0;

    }

    /**
     * .container.
     */

    .container {

        /**
         * .inset.
         */

        .inset {

            /**
             * Padding.
             */

            padding-top: 100px;
            padding-bottom: 100px;

            /**
             * Margin.
             */

            margin-top: 50px;
            margin-bottom: 50px;

            /**
             * Background.
             */
    
            background-color: rgba(white, 0.1);

        }


    }    

}
