/**
 * .technologies-wrapper.
 */

.technologies-wrapper {

    /**
     * .row-hero.
     */

    .row-hero {

        /**
         * Padding.
         */

        padding-top: 210px;
        padding-bottom: 50px;

        /**
         * p.
         */

        p {

            /**
             * Font related.
             */
            
            font-size: 24px;
            line-height: 29px;

        }

        /**
         * p.type.
         */

        p.type {

            /**
             * 
             */

            font-size: 13px;
            text-transform: uppercase;
            font-weight: bold;

        }

    }

    /**
     * .col-content.
     */

    .col-content {

        /**
         * Margin.
         */
    
        margin-bottom: 80px;

        /**
         * .icon-text.
         */

        .icon-text {

            /**
             * Display.
             */

            display: flex;

            /**
             * Flex.
             */

            align-items: flex-start;

            /**
             * Padding.
             */

            padding-right: 30px;

            /**
             * .icon.
             */

            .icon {
               
                /**
                 * Margin.
                 */

                margin-right: 20px;

            }

        }

        /**
         * ul.
         */

        ul {
            
            /**
             * Margin.
             */

            margin-left: 16px;
            margin-bottom: 10px;

            /**
             * li.
             */

            li {

                /**
                 * Font related.
                 */

                color: $brand-two !important;

            }

        }

    }

    /**
     * .col-aux.
     */

    .col-aux {

        /**
         * Margin.
         */

        margin-bottom: 50px;

        /**
         * .image.
         */

        .image {

            /**
             * Sizing.
             */

            max-width: 100%;

            /**
             * Margin.
             */

            margin-bottom: 30px;

        }

        /**
         * .button.
         */

        .button {

            /**
             * Margin.
             */

            margin-bottom: 20px;

        }

    }


}
